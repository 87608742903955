import React, { useEffect, useState } from 'react'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import Table from '../../table/Table'
import Pagination from '../../table/Pagination'
import { Cell } from '../../table/TableCellText.d'
import { Props } from '../../../store/types.d'
import { FormateDate, ShowTags } from '../../commonComponent/commonFuction'
import { debounceSearch } from '../../../utils/debounce'
import { TableCellAppAmount, TableCellYesNo } from '../vision/TableCellAmount'
import { Link } from 'react-router-dom'
import Modal from '../../commonComponent/Modal'
import uuid from 'react-uuid'

export const AudioPlayer: React.FC<Cell> = (props) => {
    const value = props.value || ''

    return (
        <audio preload="none" controls>
            <source src={value} type="audio/mpeg"/>
        </audio>
    )
}

export const TableCellAudioLength: React.FC<Cell> = (props) => {
    const value = props.value || 0
    const minutes = Math.floor(value / 60)
    const extraSeconds = value % 60
    const minuteString = minutes < 10 ? '0' + minutes : minutes.toString()
    const extraSecondsString = extraSeconds < 10 ? '0' + extraSeconds : extraSeconds.toString()
    return <>{minuteString}:{extraSecondsString}</>
}

const TableCellDelete: React.FC<Cell> = (props) => {
    const data = props.data || {}
    const { deleteRecording } = props.data.audioStore
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const openCloseConfirmationModal = () => {
        setShowConfirmationModal(!showConfirmationModal)
    }

    const onSaveModal = () => {
        deleteRecording(data._id)
        openCloseConfirmationModal()
    }

    return <>
        <Link to='#' className='text-danger' onClick={() => setShowConfirmationModal(!showConfirmationModal)} title='Delete Recording'> Delete</Link>
    
        {showConfirmationModal ? (
            <Modal show={showConfirmationModal} modalTitle='Confirm Delete' openCloseModal=
                {openCloseConfirmationModal} onSaveModal={onSaveModal} saveButtonName='Delete' modalDialogClass='modal-dialog-centered' >
                <div className='text-wrap'>
              Are you sure you want to Delete Recording of <b>{data.probable_name}</b>&apos;s vision?
                </div>
            </Modal>
        ) : null}
    </>
}

const TableCellVisionName: React.FC<Cell> = (props) => {
    const visionId = props.data?.visions?._id
    const probableName = props.data?.probable_name || ''

    return (
        <>
            <Link key={uuid()} to={`/visions/vision/${visionId}`} >{probableName}</Link>
        </>
    )
}


const AudioListPage: React.FC<Props> = ({ rootStore }) => {
    const { audioStore, authStore, setTitle } = rootStore
    const { audioInformationData,  fetchAudioDetails, searchItem, isApiError, isLoading, apiErrorMessage, isPageOpened, excludeTestAudios } = audioStore
    const { isAdmin } = authStore
    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [excludeTestAudiosField, setExcludeTestAudiosField] = useState(excludeTestAudios)

    useEffect(() => {
        if(isAdmin && !isPageOpened){
            fetchAudioDetails(1, '', true)
        }
        setTitle('Vision - Recordings | OathZephyr')
    }, [isAdmin])

    const loadPage = (page: number) => {
        fetchAudioDetails(page, searchItem, excludeTestAudiosField)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchAudioDetails(1, e.target.value.trim(), excludeTestAudiosField))
    }

    const handleExcludeTestAudios = (e: any) => {
        const value = e.target.checked
        setExcludeTestAudiosField(value)
        fetchAudioDetails(1, searchItem, value)
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col'><h3>Vision Recordings</h3></div>
                            <div className='col-auto custom-switch mt-2'>
                                <input type="checkbox" className="custom-control-input" name='exclude_test_data' id="excludeTestDeeds" onClick={handleExcludeTestAudios} defaultChecked={excludeTestAudiosField} />
                                <label className="custom-control-label pr-2" htmlFor='excludeTestDeeds'>Exclude Test Recordings</label>
                            </div>
                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>

                        {((isApiError) && <div className='responsive alert-danger p-3 mb-4 rounded' >{apiErrorMessage}</div>)}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'probable_name', title: 'Vision Name', component: TableCellVisionName },
                                    { name: 'added_by', title: 'Added By' },
                                    { name: 'visions.engagement_is_agree_and_signing', title: 'Vision Signed', component: TableCellYesNo },
                                    { name: 'visions.agreement_plan', title: 'Amount', component: TableCellAppAmount },
                                    { name: 'audio', title: 'Recording', component: AudioPlayer },
                                    { name: 'audio_length', title: 'Length (min:sec)', component: TableCellAudioLength },
                                    { name: 'user_associated_tags', title: 'Tags', component: ShowTags },
                                    { name: 'createdAt', title: 'Created At', component: FormateDate },
                                    { name: '_id', title: 'Action', component: TableCellDelete },
                                ]}
                                data={audioInformationData.page_items.map((item) => {
                                    return { ...item, audioStore }
                                })}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={audioInformationData.currentPage}
                            totalPages={audioInformationData.totalPage}
                            totalItems={audioInformationData.totalItem}
                            itemsPerPage={audioInformationData.itemPerPage}
                            isFiltered={audioInformationData.isFiltered}
                            totalAllItems={audioInformationData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AudioListPage)
