import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import TableComp from '../table/Table'
import Pagination from '../table/Pagination'
import { Cell } from '../table/TableCellText.d'
import { Link } from 'react-router-dom'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import _ from 'lodash'
import { defaultAllObj, initialTemplate } from '../../store/InitialValues'
import { optionFiled, optionFiling, optionCompleted } from '../field/TypeList'
import { useForm } from 'react-hook-form'
import FormModal from '../commonComponent/FormModal'
import classNames from 'classnames'
import { debounceSearch } from '../../utils/debounce'
import { ValueLabel } from '../../store/types.d'
import { InfoCircle } from 'react-bootstrap-icons'

interface Props {
    rootStore: RootStore,
}

let optionFilingForFilter = [
    defaultAllObj
]
optionFilingForFilter = optionFilingForFilter.concat(optionFiling)
optionFilingForFilter = optionFilingForFilter.filter((opt) => opt.value !== '')

let optionFiledForFilter = [
    defaultAllObj
]
optionFiledForFilter = optionFiledForFilter.concat(optionFiled)
optionFiledForFilter = optionFiledForFilter.filter((opt) => opt.value !== '')

let optionCompletedForFilter = [
    defaultAllObj
]

optionCompletedForFilter = optionCompletedForFilter.concat(optionCompleted)

const TableCellMatterName: React.FC<Cell> = (props) => {
    const { client_id, matter_id, matter_name } = props.data

    return (
        <>
            <Link to={`/clients/${client_id}/matters/${matter_id}`} >{matter_name}</Link>
        </>
    )
}

export const TableCellFiling: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const [defaultValue, setDefaultValue] = useState(_.find(optionFiling, { value: props.data.filing }) || { value: '', label: '' })
    const defaultNote = props.data.filing_notes || ''
    const [showNoteModal, setShowNoteModal] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const scrollableBodyStyle = 'modal-body resource'

    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (defaultNote !== data.note) {
            isNoteChanged = true
        }
        props.data.deedFilingStore.markFiling(value, defaultValue.value, data.note, isNoteChanged)
        openCloseNoteModal()
    }

    const onChange = (data: any) => {
        if (data.value !== defaultValue?.value) {
            setDefaultValue(data)
            props.data.deedFilingStore.markFiling(value, data.value, defaultNote, false)
        }
    }

    return (
        <>
            {showNoteModal && (
                <><FormModal show={showNoteModal} modalTitle={`Add/Edit Filling Note - ${props.data.matter_name}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered' includeHeader={true}>

                    <form onSubmit={handleSubmit(onSaveNote)}>
                        <fieldset>
                            <div className={scrollableBodyStyle}>
                                <div className='form-group'>
                                    <textarea
                                        ref={register}
                                        name='note'
                                        defaultValue={defaultNote}
                                        style={{height:'300px'}}
                                        className={classNames('form-control', { 'is-invalid': errors.note })} />
                                    {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary">Update</button>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>
                </>)}
            <div className='deed-filing'>
                <ReactSelectComponent
                    isSearchable={true}
                    options={optionFiling}
                    value={defaultValue}
                    handleOnChangeReact={onChange}
                />
                {(defaultValue.value !== '' || defaultNote !== '') && <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)} >Notes</Link>}
            </div>
        </>
    )
}

const TableCellFilingDetails: React.FC<Cell> = (props) => {
    const filingBy = props.data.filing_by || ''
    const filingDate = props.data.filing_date || ''
    const details = <div>{filingBy}<br />{filingDate}</div>

    return (
        <>{details}</>
    )
}

export const TableCellFiled: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const [defaultValue, setDefaultValue] = useState(_.find(optionFiled, { value: props.data.filed }) || { value: '', label: '' })
    const defaultNote = props.data.filed_notes || ''
    const [showNoteModal, setShowNoteModal] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const scrollableBodyStyle = 'modal-body resource'

    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (defaultNote !== data.note) {
            isNoteChanged = true
        }
        props.data.deedFilingStore.markFiled(value, defaultValue.value, data.note, isNoteChanged)
        openCloseNoteModal()
    }

    const onChange = (data: any) => {
        if (data.value !== defaultValue?.value) {
            setDefaultValue(data)
            props.data.deedFilingStore.markFiled(value, data.value, defaultNote, false)
        }
    }

    return (
        <>
            {showNoteModal && (
                <><FormModal show={showNoteModal} modalTitle={`Add/Edit Filed Note - ${props.data.matter_name}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered' includeHeader={true}>

                    <form onSubmit={handleSubmit(onSaveNote)}>
                        <fieldset>
                            <div className={scrollableBodyStyle}>
                                <div className='form-group'>
                                    <textarea
                                        ref={register}
                                        name='note'
                                        defaultValue={defaultNote}
                                        style={{height:'300px'}}
                                        className={classNames('form-control', { 'is-invalid': errors.note })} />
                                    {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary">Update</button>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>
                </>)}

            <div className='deed-filing'>
                <ReactSelectComponent
                    isSearchable={true}
                    options={optionFiled}
                    value={defaultValue}
                    handleOnChangeReact={onChange}
                />
                {(defaultValue.value !== '' || defaultNote !== '') && <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)} >Notes</Link>}
            </div>
        </>
    )
}

const TableCellFiledDetails: React.FC<Cell> = (props) => {
    const filedBy = props.data.filed_by || ''
    const filedAt = props.data.filed_date || ''
    const details = <div>{filedBy}<br />{filedAt}</div>

    return (
        <>{details}</>
    )
}

const TableCellCheckboxRejected: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_rejected || false
    const defaultNote = props.data.rejected_notes || ''
    const [showNoteModal, setShowNoteModal] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const scrollableBodyStyle = 'modal-body resource'

    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (defaultNote !== data.note) {
            isNoteChanged = true
        }
        props.data.deedFilingStore.markRejected(value, defaultValue, data.note, isNoteChanged)
        openCloseNoteModal()
    }

    const onChange = () => {
        props.data.deedFilingStore.markRejected(value, !defaultValue, defaultNote, false)
    }

    return (
        <>
            {showNoteModal ? (
                <><FormModal show={showNoteModal} modalTitle={`Edit Rejected Note - ${props.data.matter_name}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered' includeHeader={true}>

                    <form onSubmit={handleSubmit(onSaveNote)}>
                        <fieldset>
                            <div className={scrollableBodyStyle}>
                                <div className='form-group'>
                                    <textarea
                                        ref={register}
                                        name='note'
                                        defaultValue={defaultNote}
                                        style={{height:'300px'}}
                                        className={classNames('form-control', { 'is-invalid': errors.note })} />
                                    {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary">Update</button>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>
                </>) : null}

            <input type='checkbox' className='align-middle' key={value + 'rejected'} checked={defaultValue}  onChange={onChange} /><br />
            {(defaultValue || defaultNote !== '') && <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)} >Notes</Link>}
        </>
    )
}

const TableCellRejectedDetails: React.FC<Cell> = (props) => {
    const rejectedBy = props.data.rejected_by || ''
    const rejectedAt = props.data.rejected_date || ''
    const details = <div>{rejectedBy}<br />{rejectedAt}</div>

    return (
        <>{details}</>
    )
}

const TableCellCheckboxRefiled: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_refiled || false
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const defaultNote = props.data.refiled_notes || ''
    const [showNoteModal, setShowNoteModal] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const scrollableBodyStyle = 'modal-body resource'

    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (defaultNote !== data.note) {
            isNoteChanged = true
        }
        props.data.deedFilingStore.markFilingRefiled(value, defaultValue, data.note, isNoteChanged)
        openCloseNoteModal()
    }

    const openCloseConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal)
    }

    const onChange = () => {
        props.data.deedFilingStore.markFilingRefiled(value, !defaultValue, defaultNote, false)
        openCloseConfirmModal()
    }

    return (
        <>    
            {showNoteModal ? (
                <><FormModal show={showNoteModal} modalTitle={`Edit Re-Filed Note - ${props.data.matter_name}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered' includeHeader={true}>

                    <form onSubmit={handleSubmit(onSaveNote)}>
                        <fieldset>
                            <div className={scrollableBodyStyle}>
                                <div className='form-group'>
                                    <textarea
                                        ref={register}
                                        name='note'
                                        defaultValue={defaultNote}
                                        style={{height:'300px'}}
                                        className={classNames('form-control', { 'is-invalid': errors.note })} />
                                    {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary">Update</button>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>
                </>) : null}        
            <input type='checkbox' className='align-middle' key={value + 'refiled'} checked={defaultValue} onChange={onChange}  /><br/>
            {(defaultValue || defaultNote !== '') && <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)} >Notes</Link>}
        </>
       
    )
}

const TableCellRefiledDetails: React.FC<Cell> = (props) => {
    const refiledBy = props.data.refiled_by || ''
    const refiledAt = props.data.refiled_date || ''
    const details = <div>{refiledBy}<br />{refiledAt}</div>

    return (
        <>{details}</>
    )
}

const TableCellCheckboxCompleted: React.FC<Cell> = (props) => {
    const value = props.data._id
    const defaultValue = props.data.is_filing_completed || false
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const openCloseConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal)
    }

    const onChange = () => {
        props.data.deedFilingStore.markFilingCompleted(value, !defaultValue)
        openCloseConfirmModal()
    }

    return (
        <>            
            <input type='checkbox' className='align-middle' key={value + 'complete'} checked={defaultValue} onChange={onChange} />    
        </>
       
    )
}

const TableCellFilingCompletedDetails: React.FC<Cell> = (props) => {
    // const value = props.value
    // const defaultValue = props.data.is_completed || false
    const completedBy = props.data.filing_completed_by || ''
    const completedAt = props.data.filing_completed_date || ''
    const details = <div>{completedBy}<br />{completedAt}</div>

    return (
        <>{details}</>
    )
}

const TableCellPropertyDetails: React.FC<Cell> = (props) => {
    // const value = props.value
    // const defaultValue = props.data.is_completed || false
    const state = props.data.state_of_property || ''
    const county = props.data.county_of_property || ''
    const street = props.data.street_number || ''

    const details = <div>{state}<br />{county}<br />{street}</div>

    return (
        <>{details}</>
    )
}

const DeedFilingPage: React.FC<Props> = ({ rootStore }) => {
    const { authStore, templateStore, deedFilingStore, setTitle } = rootStore
    const { templateDetails } = templateStore
    const { deedFilingData, fetchDeedFiling, fetchUserEmail, searchItem, excludeTestDeeds, userId, page, allUserEmailWithValueLabel, tableRows, filterWithCompleted, filterWithRejected, filterWithFiled, filterWithFiling, isPageOpened } = deedFilingStore
    const all = { value: 'All', label: 'All' }
    const { isAdmin } = authStore
    const initialFiled = optionFiledForFilter.find((item) => item.value === filterWithFiled) || defaultAllObj
    const initialFiling = optionFilingForFilter.find((item) => item.value === filterWithFiling) || defaultAllObj
    const initialCompleted = optionCompletedForFilter.find((item) => item.value === filterWithCompleted) || defaultAllObj

    const userEmailWithAll = allUserEmailWithValueLabel.map((state) => {
        return { value: state.value, label: state.label }
    })
    userEmailWithAll.splice(0, 0, all)
    const searchedUserId = userEmailWithAll.find((email: ValueLabel) => email.value === userId)
    const [searchByUser, setSearchByUser] = useState((searchedUserId === undefined) ? all : searchedUserId)

    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [filterWithCompletedValue, setFilterWithCompleted] = useState(initialCompleted)
    const [filterWithRejectedValue, setFilterWithRejected] = useState(filterWithRejected)
    const [filterWithFiledValue, setFilterWithFiled] = useState(initialFiled)
    const [filterWithFilingValue, setFilterWithFiling] = useState(initialFiling)
    const [excludeTestDeedsField, setExcludeTestDeedsField] = useState(excludeTestDeeds)

    useEffect(() => {
        setTitle('Deed Filing | OathZephyr')
        fetchUserEmail()
        templateDetails.set(initialTemplate)
    }, [fetchDeedFiling, templateDetails, fetchUserEmail])

    const loadPage = (page: number) => {
        fetchDeedFiling(page, searchItem, searchByUser.value, filterWithRejected, filterWithCompletedValue.value, filterWithFilingValue.value, filterWithFiledValue.value, excludeTestDeedsField)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchDeedFiling(1, e.target.value.trim(), searchByUser.value, filterWithRejected, filterWithCompletedValue.value, filterWithFilingValue.value, filterWithFiledValue.value, excludeTestDeedsField))
    }

    const handleFilterWithRejected = (e: any) => {
        const value = e.target.checked
        setFilterWithRejected(value)
        fetchDeedFiling(page, searchItem, searchByUser.value, value, filterWithCompletedValue.value, filterWithFilingValue.value, filterWithFiledValue.value, excludeTestDeedsField)
    }

    const handleOnChangeCompleted = (data: any) => {
        if (data !== null) {
            setFilterWithCompleted(data)
            fetchDeedFiling(page, searchItem, searchByUser.value, filterWithRejected, data.value, filterWithFilingValue.value, filterWithFiledValue.value, excludeTestDeedsField)
        }
    }

    const handleOnChangeFiling = (data: any) => {
        if (data !== null) {
            setFilterWithFiling(data)
            fetchDeedFiling(page, searchItem, searchByUser.value, filterWithRejected, filterWithCompleted, data.value, filterWithFiledValue.value, excludeTestDeedsField)
        }
    }

    const handleOnChangeFiled = (data: any) => {
        if (data !== null) {
            setFilterWithFiled(data)
            fetchDeedFiling(page, searchItem, searchByUser.value, filterWithRejected, filterWithCompleted, filterWithFilingValue.value, data.value, excludeTestDeedsField)
        }
    }

    const handleOnChangeUserEmail = (data: any) => {
        if (data !== null) {
            setSearchByUser(data)
            fetchDeedFiling(page, searchItem, data.value, filterWithRejected, filterWithCompleted, filterWithFilingValue.value, filterWithFiledValue.value, excludeTestDeedsField)
        }
    }

    const handleExcludeTestDeeds = (e: any) => {
        const value = e.target.checked
        setExcludeTestDeedsField(value)
        fetchDeedFiling(page, searchItem, searchByUser.value, filterWithRejected, filterWithCompleted, filterWithFilingValue.value, filterWithFiledValue.value, value)
    }

    useEffect(() => {
        if(!isPageOpened){
            fetchDeedFiling(1, '', '', false, 'is_not_completed', 'ALL', 'ALL', true)
        }
    })

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col'>
                                <h3>Deed Filing</h3>
                            </div>
                            <div className='col-auto ml-auto'>
                                <div className='popover-btn position-relative'>
                                    <InfoCircle size={20} className='text-primary' />
                                    <div className='popover-content'>
                                        The record will be visible if it&apos;s marked completed in &ldquo;Deed Research&rdquo; and the singing date is after 1st Oct 2022 but not the future date.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-3'>
                            <label className='col-auto pr-1 pt-2'>Filter by Filing:</label>
                            <div className='col-sm mb-4'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={optionFilingForFilter}
                                    value={filterWithFilingValue}
                                    handleOnChangeReact={handleOnChangeFiling}
                                />
                            </div>
                            <label className='col-auto pr-1 pt-2'>Filter by Filed:</label>
                            <div className='col-sm mb-4'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={optionFiledForFilter}
                                    value={filterWithFiledValue}
                                    handleOnChangeReact={handleOnChangeFiled}
                                />
                            </div>
                            <label className='col-auto pr-1 pt-2'>Filter by Market:</label>
                            <div className='col-sm'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={userEmailWithAll}
                                    value={searchByUser}
                                    handleOnChangeReact={handleOnChangeUserEmail}
                                />
                            </div>
                            
                            {/* <div className='col-sm mb-4'></div> */}
                        </div>
                        <div className='row'>
                            <label className='col-auto pr-1 pt-2'>Filter by Completed:</label>
                            <div className='col-sm-3 mb-4'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={optionCompletedForFilter}
                                    value={filterWithCompletedValue}
                                    handleOnChangeReact={handleOnChangeCompleted}
                                />
                            </div>
                            <div className='col-auto ml-auto pt-2 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='filterWithRejected' id="filterWithRejected" onClick={handleFilterWithRejected} defaultChecked={filterWithRejectedValue} />
                                <label className="custom-control-label pr-2" htmlFor='filterWithRejected'>Is Rejected</label>
                            </div>
                            {isAdmin ? <div className='col-auto pt-2 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='exclude_test_data' id="excludeTestDeeds" onClick={handleExcludeTestDeeds} defaultChecked={excludeTestDeedsField} />
                                <label className="custom-control-label pr-2" htmlFor='excludeTestDeeds'>Exclude Test Deeds</label>
                            </div> :  null }
                            
                            <div className='col-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>
                        
                        {((deedFilingStore.isApiError) && <div className='responsive alert-danger p-3 mb-4 rounded' >{deedFilingStore.apiErrorMessage}</div>)}
                        <div className='table-responsive'>

                            <TableComp
                                isLoading={deedFilingStore.isLoading}
                                unique_key='_id'
                                columns={[
                                    {
                                        name: 'filing', title: 'Filing',
                                        component: TableCellFiling
                                    },
                                    {
                                        name: 'filed', title: 'Filed',
                                        component: TableCellFiled 
                                    },
                                    {
                                        name: 'is_rejected', title: 'Rejected',
                                        component: TableCellCheckboxRejected 
                                    },
                                    {
                                        name: 'is_refiled', title: 'Re-Filed',
                                        component: TableCellCheckboxRefiled 
                                    },
                                    {
                                        name: 'is_filing_completed', title: 'Completed',
                                        component: TableCellCheckboxCompleted 
                                    },
                                    {
                                        name: 'matter_name', title: 'Matter Name', component: TableCellMatterName
                                    },
                                    { name: 'signing_date', title: 'Signing Date' },
                                    {
                                        name: 'grantee', title: 'Grantee'
                                    },
                                    { name: 'state_of_property', class:'wrap-cell', title: 'State/County/Street', component: TableCellPropertyDetails },
                                    { name: 'filing_notes', class:'pre-wrap', title: 'Filling Notes' },
                                    { name: 'filed_notes', class:'pre-wrap', title: 'Filed Notes' },
                                    { name: 'rejected_notes', class:'pre-wrap', title: 'Rejected Notes' },
                                    { name: 'refiled_notes', class:'pre-wrap', title: 'Refiled Notes' },
                                    { name: 'filing_by', title: 'Filing By/Filing Date', component: TableCellFilingDetails },
                                    { name: 'filed_by', title: 'Filed By/Filed Date', component: TableCellFiledDetails },
                                    { name: 'rejected_by', title: 'Rejected By/Rejected Date', component: TableCellRejectedDetails },
                                    { name: 'refiled_by', title: 'Re-Filed By/Re-Filed Date', component: TableCellRefiledDetails },
                                    { name: 'filing_completed_by', title: 'Completed Details', component: TableCellFilingCompletedDetails },
                                ]}
                                data={tableRows.map((item) => {
                                    return { ...item, deedFilingStore }
                                })}
                            ></TableComp>
                        </div>

                        <Pagination
                            currentPage={deedFilingData.currentPage}
                            totalPages={deedFilingData.totalPage}
                            totalItems={deedFilingData.totalItem}
                            itemsPerPage={deedFilingData.itemPerPage}
                            isFiltered={deedFilingData.isFiltered}
                            totalAllItems={deedFilingData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(DeedFilingPage)
