import { observable } from 'mobx'
import RootStore from './Root'
import { TrusteeInfoProps } from './types.d'
import { getTrusteeInformation } from '../api/TrusteeInformationActions'
import { initialTableDataWithPaginationDetails } from './InitialValues'

interface TrusteeInfoData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<TrusteeInfoProps>
}

class DeedStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public trusteeInformationData: TrusteeInfoData
    @observable public searchItem: string
    @observable public page: number
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.trusteeInformationData = initialTableDataWithPaginationDetails
    }

    //get the list of trustee info with details
    fetchTrusteeInfo = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = { page: page, search_item: searchItem }
        this.page = page
        this.searchItem = searchItem

        getTrusteeInformation(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.trusteeInformationData = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

}

export default DeedStore
