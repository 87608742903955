import React, { useEffect, useRef, useState } from 'react'
import RootStore from '../../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { addAppUser } from '../../api/AppUserActions'
import { handleKeyDownWithOutSpace, onChangeEmail } from '../commonComponent/WithoutAcceptSpace'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import { initialValueLabel } from '../../store/InitialValues'
import { ValueLabel } from '../../store/types.d'
import { USER_ROLE_DEED_ONLY, USER_ROLE_ORG_ADMIN } from '../../utils/constant'

interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}

const AddAppUserPage: React.FC<Props> = ({ rootStore }) => {
    const [apiErrorMessage, setApiErrorMessage] = useState('')
    const [isApiError, setIsApiError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { appUserStore, allPlanPricingTemplateStore, allEngagementTemplateStore, allTagStore, setTitle } = rootStore
    const { fetchAppUser } = appUserStore
    const { planPricingTemplatesWithValueLabel } = allPlanPricingTemplateStore
    const { engagementTemplatesWithValueLabel } = allEngagementTemplateStore
    const { tagList } = allTagStore
    const { register, handleSubmit, errors, watch } = useForm()
    const history = useHistory()
    const password = useRef({})
    const [engagementTemplate, setEngagementTemplate] = useState({ value: '', label: '' })
    const [selectedOptionOfTag, setSelectedOptionOfTag] = useState<Array<ValueLabel>>()
    const [planPricingTemplate, setPlanPricingTemplate] = useState({ value: '', label: '' })
    const [recordingStatus, setRecordingStatus] = useState(true)
    
    password.current = watch('password', '')
    const passwordLength = 16

    useEffect(() => {
        const selectedEngagement = engagementTemplatesWithValueLabel.find(item => item.value === '6555ffd55941d98c828771ac')
        if (selectedEngagement !== undefined) {
            setEngagementTemplate({ value: selectedEngagement.value, label: selectedEngagement.label })
        } else {
            setEngagementTemplate(initialValueLabel)
        }
    }, [setEngagementTemplate, engagementTemplatesWithValueLabel])

    const handleClick = () => {
        history.push('/app-users/')
    }

    const option = tagList.map((list: any) => {
        const data = {
            value: list.tag || '',
            label: list.tag || ''
        }
        return data
    })

    const handleOnPlanPricingTemplate = (data: any) => {
        if (data !== null) {
            setPlanPricingTemplate(data)
        }
    }
    const handleOnEngagementTemplate = (data: any) => {
        if (data !== null) {
            setEngagementTemplate(data)
        }
    }

    const handleOnFieldTagChange = (data: any) => {
        if (data !== null) {
            setSelectedOptionOfTag(data)
        }
    }

    const handleVisionRecordingStatus = (e: any) => {
        const value = e.target.checked
        setRecordingStatus(value)
    }

    const onSubmit = (data: Dictionary<string>) => {
        const tags = selectedOptionOfTag?.map(tag => tag.value)

        setIsLoading(true)
        const requestData = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            account_code: data.account_code,
            attorney_code: data.attorney_code,
            password: data.password,
            role: data.role,
            user_specific_price_template_id: planPricingTemplate.value,
            engagement_template_id: engagementTemplate.value,
            user_associated_tags: tags,
            default_vision_recording_status: recordingStatus
        }

        addAppUser(requestData).then((response) => {
            setIsLoading(false)

            if (response.data.success === 0) {
                setIsApiError(true)
                setApiErrorMessage(response.data.message)
            } else {
                history.push('/app-users/')
                fetchAppUser(1, '', 'add', 'ALL', true)
            }

        }).catch((e) => {
            setIsLoading(false)
            setIsApiError(true)
            setApiErrorMessage(e.message)
        })
    }

    useEffect(() => {
        setTitle('User | Add')
        allTagStore.getAllTags()
        allEngagementTemplateStore.getAllEngagementTemplateNameIDs()
        allPlanPricingTemplateStore.getAllPlanPricingTemplateNameIDs()
        
    },[])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Users', to: '/app-users/' },
            { name: 'Add', isActive: true }
        ]} />
        <div className="card">
            <div className="card-header">
            Add User
            </div>
            <div className="card-body">

                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>

                                <div className='form-group'>
                                    <label>Role</label>
                                    <div className='radio' >
                                        <label className='mr-2'><input name='role' type='radio' value='EMPLOYEE' defaultChecked ref={register} /> Team-iPad</label>
                                        <label><input name='role' type='radio' value='ADMIN' ref={register} /> Admin-iPad</label>
                                        <label><input className='ml-2' name='role' type='radio' value='ADMIN_CMS' ref={register} /> Team-CMS</label>
                                        <label><input className='ml-2' name='role' type='radio' value='TEAM_CMS_IPAD' ref={register} /> Team-iPad/CMS</label>
                                        <label><input className='ml-2' name='role' type='radio' value='ADMIN_CMS_TEAM_IPAD' ref={register} /> Admin-CMS/Team-iPad</label>
                                        <label><input className='ml-2' name='role' type='radio' value={USER_ROLE_DEED_ONLY} ref={register} /> Deed Only</label>
                                        <label><input className='ml-2' name='role' type='radio' value={USER_ROLE_ORG_ADMIN} ref={register} /> Organization Admin</label>
                                    </div>
                                    {errors.role && (<div className='invalid-feedback'>{errors.role.message}</div>)}
                                </div>

                                <InputField
                                    label='First Name *'
                                    fieldName='first_name'
                                    register={register}
                                    errors={errors.first_name}
                                    placeholder='Enter First Name'
                                    required={true}
                                />

                                <InputField
                                    label='Last Name *'
                                    fieldName='last_name'
                                    register={register}
                                    errors={errors.last_name}
                                    placeholder='Enter Last Name'
                                    required={true}
                                />

                                <div className='form-group'>
                                    <label>User Associated Tags</label>
                                    <ReactSelectComponent
                                        options={option}
                                        isMulti={true}
                                        value={selectedOptionOfTag}
                                        handleOnChangeReact={handleOnFieldTagChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email *</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'Invalid email address'
                                            }
                                        })}
                                        name='email'
                                        type='text'
                                        onChange={onChangeEmail}
                                        onKeyDown={handleKeyDownWithOutSpace}
                                        className={classNames('form-control', { 'is-invalid': errors.email })}
                                        placeholder='Enter email'
                                    />
                                    {errors.email && (<div className='invalid-feedback'>{errors.email.message}</div>)}
                                </div>

                                <div className="form-group">
                                    <label>Password *</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required',
                                            pattern: {
                                                value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
                                                message: 'Password must be at least 8 and at most 16 character long and should have one uppercase alphabet, one lowercase alphabet, one number and one special character'
                                            }
                                        })}
                                        maxLength={passwordLength}
                                        name='password'
                                        type='password'
                                        autoComplete='new-password'
                                        onKeyDown={handleKeyDownWithOutSpace}
                                        className={classNames('form-control', { 'is-invalid': errors.password })}
                                        placeholder='Enter Password'
                                    />

                                    {errors.password && (<div className='invalid-feedback'>{errors.password.message}</div>)}
                                </div>

                                <div className="form-group">
                                    <label>Confirm Password *</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required',
                                            validate: value =>
                                                value === password.current || 'Confirm password does not match'
                                        })}
                                        maxLength={passwordLength}
                                        name='confirm_password'
                                        type='password'
                                        onKeyDown={handleKeyDownWithOutSpace}
                                        className={classNames('form-control', { 'is-invalid': errors.confirm_password })}
                                        placeholder='Enter Confirm Password'
                                    />
                                    {errors.confirm_password && (<div className='invalid-feedback'>{errors.confirm_password.message}</div>)}
                                </div>

                                <InputField
                                    label='Xero Account Code'
                                    fieldName='account_code'
                                    register={register}
                                    errors={errors.account_code}
                                    placeholder='Enter Xero Account Code'
                                />

                                <InputField
                                    label='Xero Attorneys'
                                    fieldName='attorney_code'
                                    register={register}
                                    errors={errors.attorney_code}
                                    placeholder='Enter Xero Attorneys'
                                />
                                <div className='form-group'>
                                    <label>Plan Pricing Template</label>
                                    <ReactSelectComponent
                                        placeholder='Select...'
                                        options={planPricingTemplatesWithValueLabel}
                                        value={planPricingTemplate}
                                        handleOnChangeReact={handleOnPlanPricingTemplate}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label>Engagement Template *</label>
                                    <ReactSelectComponent
                                        placeholder='Select...'
                                        options={engagementTemplatesWithValueLabel}
                                        value={engagementTemplate}
                                        handleOnChangeReact={handleOnEngagementTemplate}
                                    />
                                </div>
                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={true}
                                            ref={register}
                                            className="custom-control-input" name='default_vision_recording_status' id="visionRecordingStatus" onClick={handleVisionRecordingStatus} />
                                        <label className="custom-control-label" htmlFor='visionRecordingStatus'>Enable Vision Recording</label>
                                        {errors.default_vision_recording_status ? (<div className='invalid-feedback'>{errors.default_vision_recording_status.message}</div>) : <small className='form-text text-muted switch-help-text'></small>}
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                        {isLoading ? 'Loading...' : 'Submit'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddAppUserPage)
