import { action, computed, observable, runInAction } from 'mobx'
import RootStore from './Root'
import SingleTemplateStore from './SingleTemplateStore'
import { getEmailLogsOfMatter } from '../api/EmailLogAction'
import {
    getMatterClientDraftAdjustments,
    addUpdateClientDraftAdjustmentForMatter
} from '../api/ClientDraftAdjustmentActions'
import {
    getClients,
    getClientDetail,
    getTemplateDetailsWithValues,
    getGeneratedDocx,
    getGeneratedPDF,
    getHTMLForDocument,
    zipDocuments,
    lengthOfGroupAnswer,
    getGeneratedNewPDF,
    checkForHTMLDocument,
    getClientMattersBasic,
    getMatterDocuments,
    zipHTMLDocuments,
    getGeneratedTableOfContentsPDF,
    getPDFWithResetPage
} from '../api/ClientActions'

import {
    ClientDraftAdjustmentInfo,
    ClientInformation,
    ClientMatter,
    ClientMatters,
    Dictionary,
    EmailLogCronInformation,
    SortConfig,
    UnansweredDetails
} from './types.d'

import {
    initialClientDetailsForMatters,
    initialTableDataWithPaginationDetails,
    initialTemplate,
    initialValuesClientMatter
} from './InitialValues'

interface ClientData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<ClientInformation>
}

interface ResponseZip {
    unansweredDetail: Array<UnansweredDetails>
    path:string
}

const initialClientDetails = {
    _id: '',
    is_imported: 0,
    first_name: '',
    last_name: '',
    full_name: '',
    middle_name: '',
    email: '',
    is_client: 0,
    added_by: '',
    category: 'Client',
    type: '',
    state: '',
    employee: '',
    gender: '',
    birthdate: '',
    street: '',
    city: '',
    county: '',
    country: '',
    county_ref: '',
    marital_status: '',
    children: '',
    date_of_married: '',
    life_partner: '',
    is_updated: false,
    createdAt: '',
    updatedAt: '',
    phone_numbers: {
        home: '',
        work: '',
        mobile: ''
    },
    lawmatics_contact_id: '',
    lawmatics_matter_id: '',
    zephyr_probable_uid: '',
    user_associated_tags: []
}


class ClientStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public clientData: ClientData
    @observable public previousClientData: ClientData
    @observable public clientMatters: ClientMatters
    @observable public clientDetail: ClientInformation
    @observable public isLoadingForModal: boolean
    @observable public previewDocument: SingleTemplateStore
    @observable public docxURL: string
    @observable public matter: ClientMatter
    @observable public emailLogs: Array<EmailLogCronInformation>
    @observable public searchItem: string
    @observable public searchByState: string
    @observable public filterWithSource: string
    @observable public excludeTestClients: boolean
    @observable public isDataUpdated: boolean
    @observable public isClient: boolean
    @observable public page: number
    @observable public clientDataHistory: Array<ClientData>
    @observable public groupAnswersUUIDs: Dictionary<Array<string>>
    @observable public isPageOpened: boolean
    @observable public searchKeyword: string
    @observable public clientDraftAdjustments: Array<ClientDraftAdjustmentInfo>
    // @observable public clientDocuments: Array<ClientDocuments>
    @observable public disableDownloadAllDoc: boolean  
    @observable public cdaCountForMatter: number

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.isLoadingForModal = false
        this.clientDetail = initialClientDetails
        this.searchItem = ''
        this.page = 1
        this.clientMatters = {
            client: initialClientDetailsForMatters,
            matters: [],
            relateds: []
        }
        this.clientData = initialTableDataWithPaginationDetails
        this.previousClientData = initialTableDataWithPaginationDetails
        this.previewDocument = new SingleTemplateStore(initialTemplate)
        this.docxURL = ''
        this.matter = initialValuesClientMatter
        this.clientDataHistory = []
        this.isClient = false
        this.isDataUpdated = false
        this.filterWithSource = 'ALL'
        this.searchByState = 'All'
        this.excludeTestClients = true
        this.groupAnswersUUIDs = {}
        this.emailLogs = []
        this.searchKeyword = ''
        this.clientDraftAdjustments = []
        this.disableDownloadAllDoc = false
        this.cdaCountForMatter = 0
        // this.clientDocuments = []
    }

    // reloadDocumentLinks = async () => {
    //     this.isLoading = true
    //     try {
    //         const docs: Array<ClientDocuments> = []
    //         for (let i = 0; i < this.matter.documents.length; i++) {
    //             let docLinks: Array<Array<ClientLinks>> = []

    //             const document = this.matter.documents[i]
    //             const clientId = this.matter.client._id
    //             const matterId = this.matter._id
    //             const matterType = this.matter.type
           
    //             const currentDocumentId = document._id
            
    //             if (document.should_create_document_for_both && matterType == 'JOINT') {
              
    //                 const forClient = await this.isHTMLDocumentAvailable(document._id, matterId, '0', 'false')
                
    //                 const forSpouse = await this.isHTMLDocumentAvailable(document._id, matterId, '0', 'true')


    //                 docLinks = [
    //                     [
    //                         { label: 'Preview for Client', link: `/clients/${clientId}/matters/${matterId}/preview-document/${currentDocumentId}/${false}/0` },
    //                         { label: 'Download Docx for Client', link: '#', arguments: { matterId, currentDocumentId, forSpouse: false, UUID: '0' }, pdfOrDoc: 'doc' },
    //                         { label: 'Download PDF for Client', link: '#', arguments: { matterId, currentDocumentId, forSpouse: false, UUID: '0' }, pdfOrDoc: 'pdf' },
    //                         { label: 'Edit for Client', link: `/clients/${clientId}/matters/${matterId}/HTML-document/${currentDocumentId}/${false}/0/${forClient}` }
    //                     ],
    //                     [
    //                         { label: 'Preview for Spouse', link: `/clients/${clientId}/matters/${matterId}/preview-document/${currentDocumentId}/${true}/0` },
    //                         { label: 'Download Docx for Spouse', link: '#', arguments: { matterId, currentDocumentId, forSpouse: true, UUID: '0' }, pdfOrDoc: 'doc' },
    //                         { label: 'Download PDF for Spouse', link: '#', arguments: { matterId, currentDocumentId, forSpouse: true, UUID: '0' }, pdfOrDoc: 'pdf' },
    //                         { label: 'Edit for Spouse', link: `/clients/${clientId}/matters/${matterId}/HTML-document/${currentDocumentId}/${true}/0/${forSpouse}` }
    //                     ]
    //                 ]
    //             } else if (document.enable_multiple_document_mode) {

    //                 await this.getLengthFromGroupAnswer(matterId, this.matter.documents[i]._id)

    //                 const count = await this.groupAnswersUUIDs[currentDocumentId]
    //                 for (let j = 0; j < count.length; j++) {
    //                     const links: Array<ClientLinks> = []
    //                     const index = j + 1
    //                     const forUUID = await this.isHTMLDocumentAvailable(document._id, matterId, count[j], 'false')
    //                     links.push({ label: `Preview (${index})`, link: `/clients/${clientId}/matters/${matterId}/preview-document/${currentDocumentId}/false/${count[j]}` })
    //                     links.push({ label: `Download Docx (${index})`, link: '#', arguments: { matterId, currentDocumentId, forSpouse: false, UUID: count[j] }, pdfOrDoc: 'doc' })
    //                     links.push({ label: `Download PDF (${index})`, link: '#', arguments: { matterId, currentDocumentId, forSpouse: false, UUID: count[j] }, pdfOrDoc: 'pdf' })
    //                     links.push({ label: `Edit (${index})`, link: `/clients/${clientId}/matters/${matterId}/HTML-document/${currentDocumentId}/false/${count[j]}/${forUUID}` })
    //                     docLinks.push(links)
    //                 }
    //             } else {
    //                 const isHTMLDocEdited = await this.isHTMLDocumentAvailable(document._id, matterId, '0', 'false')

    //                 docLinks = [
    //                     [
    //                         { label: 'Preview', link: `/clients/${clientId}/matters/${matterId}/preview-document/${currentDocumentId}/false/0` },
    //                         { label: 'Download Docx', link: '#', arguments: { matterId, currentDocumentId, forSpouse: false, UUID: '0' }, pdfOrDoc: 'doc' },
    //                         { label: 'Download PDF', link: '#', arguments: { matterId, currentDocumentId, forSpouse: false, UUID: '0' }, pdfOrDoc: 'pdf' },
    //                         { label: 'Edit', link: `/clients/${clientId}/matters/${matterId}/HTML-document/${currentDocumentId}/false/0/${isHTMLDocEdited}` }
    //                     ]
    //                 ]
    //             }
    //             docs.push({ document_id: currentDocumentId, links: docLinks })
    //         }
    //         this.clientDocuments = docs
    //         this.isLoading = false
    //     } catch(error) {
    //         if (error.request.status === 401) {
    //             this.rootStore.authStore.setAuthToken('')
    //         }

    //         this.isLoading = false
    //         this.isApiError = true
    //         this.apiErrorMessage = error.message
    //     }
        
    // }

    setMatters = (data: ClientMatters): void => {
        this.clientMatters = {
            client: data.client,
            matters: data.matters,
            relateds: data.relateds
        }
    }
    
    setSearchKeyword = (searchItem: string): void => {
        this.searchKeyword = searchItem
    }

    @computed get filteredDocumentsData () {
        const filteredDocuments = this.matter.documents.filter((item) => {
            return item.name.toLowerCase().includes(this.searchKeyword.toLowerCase().trim()) || this.searchKeyword.trim() === ''
        })
        return filteredDocuments.map((item) => {
            return {
                _id: item._id,
                document: item,
                generateDocx: this.generateDocx,
                generateNewPDF: this.generateNewPDF,
                templateDetails: this.rootStore.templateStore.templateDetails,
                setCurrentMatter: this.setCurrentMatter,
                generateTableOfContentPDF: this.generateTableOfContentPDF,
                generatePDFWithPageReset: this.generatePDFWithPageReset
            }
        })
    }

    //get the list of Clients with details
    fetchClient = (page: number, searchItem: string, action: string, searchByState: string, isClient: boolean, isUpdated: boolean, source: string, excludeTestClients: boolean): void => {
        this.isPageOpened = true
        if (action === 'change_page') {
            this.page = page

            for (let i = 0; i < this.clientDataHistory.length; i++) {
                if (this.clientDataHistory[i].currentPage === page) {
                    this.clientDataHistory.push(this.clientData)
                    this.clientData = this.clientDataHistory[i]
                    return
                }
            }
            this.clientDataHistory.push(this.clientData)
        } else if (action === 'search_item') {
            this.clientDataHistory = []
            this.searchItem = searchItem
            this.page = 1
        } else if (action === 'change_filter') {
            this.clientDataHistory = []
            this.searchByState = searchByState
            this.excludeTestClients = excludeTestClients
            this.isClient = isClient
            this.isDataUpdated = isUpdated
            this.filterWithSource = source
            this.searchItem = searchItem
            this.page = page
        }

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem, search_by_state: this.searchByState, is_client: this.isClient, is_updated: this.isDataUpdated, exclude_test_clients: this.excludeTestClients, filter_with_source: this.filterWithSource }

        getClients(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.clientData = response.data.data
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    setClientDetail = (data: string): void => {
        this.isLoadingForModal = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getClientDetail(data).then((response) => {
            this.isLoadingForModal = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.clientDetail = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    @action
    async getLengthFromGroupAnswer(matterId: string, documentId: string): Promise<void> {
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = {
            matter_id: matterId,
            document_id: documentId
        }
        await lengthOfGroupAnswer(requestData).then((response) => {

            if (response.data.success === 0) {
                runInAction(() => {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                })
                return
            }
            runInAction(() => {
                this.groupAnswersUUIDs[documentId] = response.data.data
            })

        }).catch((error) => {
            runInAction(() => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isApiError = true
                this.apiErrorMessage = error.message
            })
            return
        })
    }

    resetClientDetails = () => {
        this.clientDetail = initialClientDetails
    }

    resetLoadingProcess = (): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        this.isLoading = false
    }

    setClientMatters = (clientId: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = {
            client_id: clientId
        }
        getClientMattersBasic(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.clientMatters = response.data.data
            this.previewDocument = new SingleTemplateStore(initialTemplate)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
            return
        })
    }

    setCurrentMatter = (matterId: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = {
            matter_id: matterId
        }

        getMatterDocuments(requestData).then(async (response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.matter = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
            return
        })
    }

    resetCurrentMatter = () => {
        this.matter = initialValuesClientMatter
    }

    setPreviewDocument = (matterId: string, documentId: string, forSpouse = false, UUID = '0'): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = {
            matter_id: matterId,
            template_id: documentId,
            for_spouse: forSpouse,
            UUID
        }
        getTemplateDetailsWithValues(requestData).then((response) => {

            if (response.data.success === 0) {
                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.previewDocument = new SingleTemplateStore(response.data.data)
            this.isLoading = false
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
            return
        })
    }


    generateDocx = (matterId: string, documentId: string, for_spouse = false, UUID): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = {
            matter_id: matterId,
            template_id: documentId,
            UUID,
            for_spouse: for_spouse
        }

        getGeneratedDocx(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
            }
            window.open(`${process.env.REACT_APP_BASEURL}/${response.data.data.responsePath}`, '_self')
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    allDocumentsInZipExport = (matterId: string, action: string, cb: (response: ResponseZip | undefined) => void): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = {
            matter_id: matterId,
            action: action
        }

        zipDocuments(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                cb(undefined)
            }
            cb(response.data.data)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
            cb(undefined)
        })
    }

    documentsHTMLInZip = (matterId: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = {
            matter_id: matterId
        }

        zipHTMLDocuments(requestData).then((response) => {
            this.isLoading = false
            
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            window.open(`${process.env.REACT_APP_BASEURL}/${response.data.data}`, '_blank')
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    generatePDF = (matterId: string, documentId: string, for_spouse = false, UUID = '0'): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = {
            matter_id: matterId,
            template_id: documentId,
            UUID,
            for_spouse: for_spouse
        }

        getGeneratedPDF(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            window.open(`${process.env.REACT_APP_BASEURL}/${response.data.data}`, '_blank')
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    generateNewPDF = (matterId: string, documentId: string, for_spouse: boolean, UUID: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = {
            matter_id: matterId,
            template_id: documentId,
            UUID,
            for_spouse: for_spouse
        }
        getGeneratedNewPDF(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            window.open(`${process.env.REACT_APP_BASEURL}/${response.data.data}`, '_blank')
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    generatePDFWithPageReset = (matterId: string, documentId: string, for_spouse: boolean, UUID: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = {
            matter_id: matterId,
            template_id: documentId,
            UUID,
            for_spouse: for_spouse
        }

        getPDFWithResetPage(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            window.open(`${process.env.REACT_APP_BASEURL}/${response.data.data}`, '_blank')
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    generateTableOfContentPDF = (matterId: string, documentId: string, for_spouse: boolean, UUID: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = {
            matter_id: matterId,
            template_id: documentId,
            UUID,
            for_spouse: for_spouse
        }

        getGeneratedTableOfContentsPDF(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            window.open(`${process.env.REACT_APP_BASEURL}/${response.data.data}`, '_blank')
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    downloadHTML = (matterId: string, documentId: string, UUID = '0'): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = {
            matter_id: matterId,
            template_id: documentId,
            UUID
        }

        getHTMLForDocument(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            window.open(`${process.env.REACT_APP_BASEURL}/${response.data.data.responsePath}`, '_blank')
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    sortClientData = (sortConfig: SortConfig): void => {
        const sortType = (sortConfig.direction === 'asc') ? 1 : -1
        this.clientData.page_items = this.clientData.page_items.slice().sort((item1: ClientInformation, item2: ClientInformation) => sortType * item1[sortConfig.key].localeCompare(item2[sortConfig.key]))
    }

    isHTMLDocumentAvailable = (documentId: string, matterId: string, UUID: string, forSpouse: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            this.isApiError = false
            this.apiErrorMessage = ''

            const requestData = {
                document_id: documentId,
                matter_id: matterId,
                UUID: UUID,
                for_spouse: forSpouse === 'true' ? true : false
            }
            checkForHTMLDocument(requestData).then((response) => {

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    resolve('false')
                }

                resolve(response.data.message)
            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject('catch')
            })
        })
    }

    fetchEmailLogsOfMatter = (matterId: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getEmailLogsOfMatter(matterId).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.emailLogs = response.data.data
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    fetchClientDraftAdjustment = (matterId: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getMatterClientDraftAdjustments(matterId).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.clientDraftAdjustments = response.data.data
            this.cdaCountForMatter = this.clientDraftAdjustments.length
            const notCompletedCda = response.data.data.find((item) => item.is_completed === false)
            
            if (notCompletedCda && !notCompletedCda.is_completed) {
                this.disableDownloadAllDoc = true
            } else {
                this.disableDownloadAllDoc = false
            }
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    addUpdateClientDraftAdjustment = (clientDraftAdjustment: ClientDraftAdjustmentInfo): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        addUpdateClientDraftAdjustmentForMatter(clientDraftAdjustment).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchClientDraftAdjustment(clientDraftAdjustment.matter_id)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default ClientStore
