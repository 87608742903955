import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { Cell } from '../table/TableCellText.d'
import { SortConfig, ValueLabel } from '../../store/types.d'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'
import _ from 'lodash'
import Modal from '../commonComponent/Modal'
import { UpworkJobLoader } from '../commonComponent/DotLoader'
import { ShowDetails } from '../commonComponent/commonFuction'
import { defaultAllObj } from '../../store/InitialValues'
import { optionSource } from '../field/TypeList'
import { debounceSearch } from '../../utils/debounce'
import { TableCellTags } from '../tag/TableCellTag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

interface Props {
  rootStore: RootStore,
}

let optionSourceForFilter = [
    defaultAllObj
]
optionSourceForFilter = optionSourceForFilter.concat(optionSource)

const isValueEquivalent = (a: any, b: any) => {
    if (!_.isEqual(a, b)) {
        return false
    }
    return true
}

const ConvertTitleCase = (string: string) => {

    if (string !== undefined) {
        return string.charAt(0).toLocaleUpperCase() + string.slice(1).toLocaleLowerCase()
    }
    return string
}

const TableCellSource: React.FC<Cell> = (props) => {
    const value = props.value || ''

    return (
        <>
            {(value === 'OATHZEPHYR') ? 'Oath Zephyr' : _.capitalize(value)}
        </>
    )
}
const ShowNameWithLawLink: React.FC<Cell> = (props) => {
    const value = props.value || ''
    const data = props.data || {}

    return <div className='d-flex'>
        {value}
        {data.lawmatics_matter_id ? 
            <a 
                href={`${process.env.REACT_APP_LAWMATICS_MATTER_URL}/${data.lawmatics_matter_id}/activities`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className='ml-auto' 
                title='Lawmatics Matter Id'
            >
                {data.lawmatics_matter_id}
            </a> : '' }
    </div>
}

const ProbablesPage: React.FC<Props> = ({ rootStore }) => {
    const { probableStore, allStatesStore, authStore, tagStore, allTagStore, setTitle } = rootStore
    const { isAdmin } = authStore
    const { searchItem, isApiError, isLoading,isPageOpened, apiErrorMessage, probableData, fetchProbable, searchByState, filterWithSource, sortProbableData, setProbableDetail, probableDetail, excludeTestProbables } = probableStore
    const { statesWithValueLabel } = allStatesStore
    const all = { value: 'All', label: 'All' }
    const statesWithAll = statesWithValueLabel.map((state) => {
        return { value: state.value, label: state.label }
    })
    statesWithAll.splice(0, 0, all)

    const [searchItemField, setSearchItemField] = useState(searchItem)

    const [showModal, setShow] = useState(false)
    const [selectedRow, setSelectedRow] = useState({ _id: '', action: '' })
    const searchedState = statesWithAll.find((state: ValueLabel) => state.value === searchByState)
    const [searchState, setSearchState] = useState((searchedState === undefined) ? all : searchedState)

    const initialSource = optionSourceForFilter.find((item) => item.value === filterWithSource) || defaultAllObj
    const [filterSource, setFilterSource] = useState(initialSource)
    const [excludeTestProbablesField, setExcludeTestProbablesField] = useState(excludeTestProbables)

    const loadPage = (page: number) => {
        fetchProbable(page, searchItem, 'change_page', searchState.value, filterSource.value, excludeTestProbablesField)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchProbable(1, e.target.value.trim(), 'search_item', searchState.value, filterSource.value, excludeTestProbablesField))
    }

    const onTableAction = (action: string, value: any, data: any) => {
        setProbableDetail(data._id)
        setSelectedRow({ _id: data._id, action: action })
        openCloseModal()
    }

    const openCloseModal = () => {
        setShow(!showModal)
    }
    
    const TableCellViewDetails: React.FC<Cell> = (props) => {
        const value = props.data._id || ''
        const data = props.data || {}
    
        return (
            <>
                <Link to='#' data-target='#myModal' key={uuid()} className='mr-2' onClick={() => {
                    if (onTableAction != null) {
                        onTableAction('viewDetails', value, data)
                    }
                }}>View Details</Link>
    
                {showModal && isValueEquivalent({ _id: data._id, action: 'viewDetails' }, selectedRow) ? (
                    <Modal show={showModal}
                        modalTitle={data.full_name}
                        openCloseModal={openCloseModal}
                        modalDialogClass='modal-lg'
                        cancelButtonName='Close'
                        isScrollable={true}
                        cancelButton={true}
                        isSaveButton={false}
                    >
    
                        {isLoading || (data._id !== probableDetail._id) ? <UpworkJobLoader /> : <>
                            <div className='row'>
                                <div className='col'><b>General Information</b></div>
                            </div><hr />
    
                            <ShowDetails title='First Name' value={probableDetail.first_name} />
                            <ShowDetails title='Middle Name' value={probableDetail.middle_name} />
                            <ShowDetails title='Last Name' value={probableDetail.last_name} />
                            <ShowDetails title='Is Client' value={probableDetail.is_client === 1 ? 'Yes' : 'No'} />
                            <ShowDetails title='From Daylite' value={probableDetail.is_imported === 1 ? 'Yes' : 'No'} />
                            {probableDetail.is_imported !== 1 ? <ShowDetails title='Modifier Name' value={probableDetail.employee} /> : null}
                            <ShowDetails title='Added By' value={probableDetail.added_by} />
                            <ShowDetails title='Type' value={ConvertTitleCase(probableDetail.type)} />
                            <ShowDetails title='Gender' value={ConvertTitleCase(probableDetail.gender)} />
                            <ShowDetails title='Birth Date' value={probableDetail.birthdate} />
                            <ShowDetails title='Mobile Number' value={probableDetail.phone_numbers?.mobile || ''} />
                            <ShowDetails title='Home Contact Number' value={probableDetail.phone_numbers?.home || ''} />
                            <ShowDetails title='Work Contact Number' value={probableDetail.phone_numbers?.work || ''} />
                            <ShowDetails title='Email Address' value={probableDetail.email} />
                            <ShowDetails title='Marital Status' value={ConvertTitleCase(probableDetail.marital_status)} />
                            {probableDetail.marital_status === 'MARRIED' ?
    
                                <>
                                    <ShowDetails title='Spouse First Name' value={probableDetail.spouse_first_name} />
                                    <ShowDetails title='Spouse Last Name' value={probableDetail.spouse_last_name} />
                                </>
                                : null
                            }
    
                            <hr />
                            <div className='row'><div className='col'><b>Address Information</b></div></div>
                            <hr />
    
                            <ShowDetails title='Street' value={probableDetail.street} />
                            <ShowDetails title='City' value={probableDetail.city} />
                            <ShowDetails title='State' value={probableDetail.state} />
                            <ShowDetails title='Country' value={ConvertTitleCase(probableDetail.country)} />
                            
                            {isAdmin ? 
                                <>
                                    <hr />
                                    <div className='row'><div className='col'><b>Lawmatics Information</b></div></div>
                                    <hr />
        
                                    <ShowDetails title='Matter Id' value={probableDetail.lawmatics_matter_id} setLink={
                                        <a 
                                            href={`${process.env.REACT_APP_LAWMATICS_MATTER_URL}/${probableDetail.lawmatics_matter_id}/activities`} 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            className='ml-auto display-6' 
                                            title='Lawmatics Matter Id'
                                        ><FontAwesomeIcon icon={faExternalLinkAlt} />
                                        </a>
                                    }/>
                                    <ShowDetails title='Contact Id' value={probableDetail.lawmatics_contact_id} setLink={
                                        <a 
                                            href={`${process.env.REACT_APP_LAWMATICS_CONTACT_URL}/${probableDetail.lawmatics_contact_id}/activities`} 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            className='ml-auto display-6' 
                                            title='Lawmatics Contact Id'
                                        ><FontAwesomeIcon icon={faExternalLinkAlt} />
                                        </a>
                                    }/>
                                    <ShowDetails title='Probable Uid' value={probableDetail.zephyr_probable_uid} />
                                </> : null}
                        </>
                        }
                    </Modal>) : null
                }
            </>
        )
    }

    const shouldSort = (sortConfig: SortConfig) => {
        sortProbableData(sortConfig)
    }

    const handleOnChangeState = (data: any) => {
        if (data !== null) {
            setSearchState(data)
            fetchProbable(1, searchItem, 'change_filter', data.value, filterSource.value, excludeTestProbablesField)
        }
    }

    const handleSource = (data: any) => {
        if (data !== null) {
            setFilterSource(data)
            fetchProbable(1, searchItem, 'change_filter', searchState.value, data.value, excludeTestProbablesField)
        }
    }

    const handleExcludeTestProbables = (e: any) => {
        const value = e.target.checked
        setExcludeTestProbablesField(value)
        fetchProbable(1, searchItem, 'change_filter', searchState.value, filterSource.value, value)    
    }

    useEffect(() => {
        setTitle('Probables | OathZephyr')
        if(isAdmin && !isPageOpened){
            fetchProbable(1, '', '', 'All', 'ALL', true)
        }
        allStatesStore.getAllStates()
        allTagStore.getAllTags()
    },[])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Probables
                </div>
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <label className='col-auto mt-2 pr-0'>State:</label>
                            <div className='col-sm-3'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={statesWithAll}
                                    value={searchState}
                                    handleOnChangeReact={handleOnChangeState}
                                />
                            </div>
                            <label className='col-auto mt-2 pr-0'>Source:</label>
                            <div className='col-sm-3'>
                                <ReactSelectComponent
                                    options={optionSourceForFilter}
                                    value={filterSource}
                                    handleOnChangeReact={handleSource}
                                />
                            </div>
                            <div className='col-auto my-2 ml-auto pr-0 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='exclude_test_data' id="excludeTestProbables" onClick={handleExcludeTestProbables} defaultChecked={excludeTestProbablesField} />
                                <label className="custom-control-label pr-2" htmlFor='excludeTestProbables'>Exclude Test Probables</label>
                            </div>
                            <div className='col-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'full_name', title: 'Full Name', sort: true, component: ShowNameWithLawLink },
                                    { name: 'state', title: 'State' },
                                    { name: 'source', title: 'Source', component: TableCellSource },
                                    { name: 'user_associated_tags', title: 'Tags', component: TableCellTags },
                                    { name: 'updatedAt', title: 'Updated At', sort: true },
                                    { name: 'createdAt', title: 'Created At' },
                                    { name: '_id', title: 'View', component: TableCellViewDetails },
                                ]}
                                data={probableData.page_items.map((item) => {
                                    return { ...item, tagStore, allTagStore }
                                })}
                                shouldSort={shouldSort}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={probableData.currentPage}
                            totalPages={probableData.totalPage}
                            totalItems={probableData.totalItem}
                            itemsPerPage={probableData.itemPerPage}
                            isFiltered={probableData.isFiltered}
                            totalAllItems={probableData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(ProbablesPage)