import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { initialTemplate } from '../../store/InitialValues'
import { Props } from '../../store/types.d'
import EditButton from '../commonComponent/EditButtonComponent'
import Modal from '../commonComponent/Modal'
import StateSelector from '../commonComponent/StatesSelector'
import { StateSelectorButton } from './UpdateTemplatePage'
import { toast } from 'react-toastify'

const UpdateTemplateNavbar: React.FC<Props> = ({ rootStore }) => {

    const { templateStore, allStatesStore, clientStore } = rootStore
    const { templateDetails, updateTemplate, isLoading } = templateStore
    const { previewDocument, resetCurrentMatter, setCurrentMatter, matter } = clientStore
    const { statesWithValueLabel } = allStatesStore
    const history = useHistory()

    const { clientId, matterId, spouse, UUID, isEdited, documentId } = useParams<{
        clientId: string,
        matterId: string,
        documentId: string,
        spouse: string,
        UUID: string,
        isEdited: string
    }>()

    let preview = 'btn btn-secondary'
    let editor = 'btn btn-outline-secondary'
    const isMatterDocument = (history.location.pathname.includes('/clients/')) ? true : false
    const isPreview = (history.location.pathname.includes('/preview')) ? true : false
    const isHTMLEditor = (history.location.pathname.includes('HTML')) ? true : false
    const isHTMLEdited = (isEdited !== 'false' && isHTMLEditor) ? false : (history.location.pathname.includes('/HTML')) ? true : false

    if (!isPreview) {
        preview = 'btn btn-outline-secondary'
        editor = 'btn btn-secondary'
    }

    const [showStateModal, setShowStateModal] = useState(false)
    const [showTemplateNameEdit, setShowTemplateNameEdit] = useState(false)
    const [templateName, setTemplateName] = useState('')

    const handleOnClickBack = () => {
        templateDetails.set(initialTemplate)
        previewDocument.set(initialTemplate)
        resetCurrentMatter()
        if (isMatterDocument) { history.push(`/clients/${clientId}/matters/${matterId}`) } else { history.push('/templates/') }
    }

    const handleOnClickSave = async () => {
        if (isHTMLEditor === true) {

            templateDetails.saveHTML(matterId, isEdited, spouse, UUID, (htmlId) => {
                history.push(`/clients/${clientId}/matters/${matterId}/HTML-document/${documentId}/${spouse}/${UUID}/${htmlId}`)
                toast.success('Document saved')
            })
        } else if (isMatterDocument === true) {

            await updateTemplate(true, matterId)
            setCurrentMatter(matterId)
        } else {
            await updateTemplate(false, '')
        }
    }

    const openCloseStateModal = () => {
        const show = (showStateModal === false) ? true : false
        setShowStateModal(show)
    }

    const openStateModel = () => {
        setShowStateModal(true)
    }

    const openEditTemplateName = () => {
        setTemplateName(templateDetails.name)
        setShowTemplateNameEdit(true)
    }

    const openCloseModalTemplateName = () => {
        const show = (showTemplateNameEdit === false) ? true : false
        setShowTemplateNameEdit(show)
    }

    const editTemplateName = () => {

        const handleOnChangeTemplateName = (e: any) => {
            setTemplateName(e.target.value)
        }
        const onSaveEditTemplateName = async () => {
            if (templateName.trim() === '') {
                return
            }
            templateDetails.name = templateName
            openCloseModalTemplateName()
        }
        return (
            <Modal show={showTemplateNameEdit} modalTitle='Edit Template Name' saveButtonName='Save' openCloseModal={openCloseModalTemplateName} onSaveModal={onSaveEditTemplateName} >
                <>
                    <label className='mb-0 mt-2'>Template Name *</label>
                    <input type='text' className='form-control' defaultValue={templateName} onChange={handleOnChangeTemplateName} name='template_name' />
                </>
            </Modal>
        )
    }

    const handleOnClickPreview = () => {
        (isEdited !== 'false' && isHTMLEditor) ? history.push(`/clients/${clientId}/matters/${matterId}/preview-HTML-document/${documentId}/${spouse}/${UUID}/${isEdited}`) : (isMatterDocument) ? history.goBack() : history.push(`/templates/update/preview/${templateDetails._id}`)
    }

    const handleOnClickEditor = () => {
        (isHTMLEditor) ? history.push(`/clients/${clientId}/matters/${matterId}/HTML-document/${documentId}/${spouse}/${UUID}/${isEdited}`) : (isMatterDocument) ? history.push(`/clients/${clientId}/matters/${matterId}/edit-document/${templateDetails._id}`) : history.push(`/templates/update/${templateDetails._id}`)
    }

    useEffect(() => {
        allStatesStore.getAllStates()
    }, [])

    return <>
        <div className="main-topbar">
            <ul className="left-topbar">
                <li>
                    <span className='mr-2'>Name:</span>
                    <span>{templateDetails.name}</span>
                    <span className="ms-2"><EditButton isDisable={isPreview || isHTMLEditor} onClickHandle={openEditTemplateName} buttonToolTip='Edit Template Name' /></span>
                </li>
                <li>
                    <span className='mr-2'>Slug:</span>
                    <span>{templateDetails.slug}</span>
                </li>
                <li>
                    <span className='mr-2'>
                        <StateSelectorButton isDisable={isPreview || isHTMLEditor} targetStore={templateDetails} onClick={openStateModel} />
                    </span>
                </li>
                {isMatterDocument && <li>
                    <span className='mr-2'>Matter:</span>
                    <span>{matter.name}</span>
                </li>}
                {isMatterDocument && <li>
                    <span className='mr-2'>Type:</span>
                    <span>{matter.type}</span>
                </li>}
                {isMatterDocument && <li>
                    <span className='mr-2'>Client:</span>
                    <span>{matter.client.first_name}</span>
                </li>}
                {isMatterDocument && <li>
                    <span className='mr-2'>State:</span>
                    <span>{matter.state_name}</span>
                </li>}
                {isMatterDocument && <li>
                    <span>{matter.package}</span>
                </li>}
            </ul>
            <ul className="right-topbar">
                <li>
                    <div className="btn-group btn-group-sm mr-2" role="group">
                        <button type="button" disabled={isHTMLEdited} className={preview} onClick={handleOnClickPreview}>Preview</button>
                        <button type="button" className={editor} onClick={handleOnClickEditor}>Editor</button>
                    </div>
                    <div>
                        <button type='button' className='btn btn-sm mr-2 btn-outline-primary' onClick={handleOnClickBack} disabled={isLoading}>Back</button>
                    </div>
                    <div>
                        <button type='button' className='btn btn-sm btn-outline-primary' onClick={async () => await handleOnClickSave()} disabled={isLoading || isPreview}>save</button>
                    </div>
                </li>
            </ul>
        </div>
        {showTemplateNameEdit ? editTemplateName() : null}
        {showStateModal ? <StateSelector selectorShow={showStateModal} targetStore={templateDetails} openCloseStateModal={openCloseStateModal} options={statesWithValueLabel} /> : null}

    </>
}

export default observer(UpdateTemplateNavbar)