import { observable } from 'mobx'
import RootStore from './Root'
import { IpadLocalInformation } from './types.d'
import { initialIpadLocalData, initialTableDataWithPaginationDetails, } from './InitialValues'
import { getIpadLocalData, getIpadLocalDataDetails } from '../api/IpadLocalDataAction'

interface IpadLocalData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<IpadLocalInformation>
}

class IpadLocalDataStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public isLoadingInfo: boolean
    @observable public ipadLocalDataDetails: IpadLocalInformation
    @observable public iPadLocalData: IpadLocalData
    @observable public searchItem: string
    @observable public page: number
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.searchItem = ''
        this.page = 1
        this.ipadLocalDataDetails = initialIpadLocalData
        this.iPadLocalData = initialTableDataWithPaginationDetails
        this.isPageOpened = false
        this.isLoadingInfo = false
    }

    //get the list of iPad local data with details
    fetchIpadLocalData = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.searchItem = searchItem
        this.page = page

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem }

        getIpadLocalData(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.iPadLocalData = response.data.data
            // this.iPadLocalDetails = initialIpadLocalData
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    getIPadLocalData = (_id: string): void => {
        this.isLoadingInfo = true
        this.isApiError = false
        this.apiErrorMessage = ''
        getIpadLocalDataDetails(_id).then((response)=>{
            this.isLoadingInfo = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.ipadLocalDataDetails = response.data.data
        }).catch((error) => {
            console.log(error)
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoadingInfo = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default IpadLocalDataStore
