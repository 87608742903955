import { observable } from 'mobx'
import RootStore from './Root'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { USER_ROLE_ADMIN_CMS, USER_ROLE_DEED_ONLY, USER_ROLE_TEAM_CMS_IPAD } from '../utils/constant'

interface JwtDecoded {
  exp: number
}

class AuthStore {

    public rootStore: RootStore
  @observable public authToken: string
  @observable public superAdmin: string
  @observable public userRole: string
  @observable public isUserLoggedIn: boolean
  @observable public isAdmin: boolean
  @observable public isTeam: boolean
  @observable public isDeedUser: boolean

  constructor(rootStore: RootStore) {
      this.rootStore = rootStore
      this.authToken = ''
      this.superAdmin = ''
      this.userRole = ''
      this.isUserLoggedIn = false
      this.isTeam = false
      this.isDeedUser = false
      this.restoreToken()
      this.reStoreSuperAdmin()
  }

  logout(): void {
      this.setAuthToken('')
      localStorage.removeItem('view')
  }

  // Set super admin email 
  setSuperAdmin(email: string): void {
      this.superAdmin = email
      localStorage.setItem('superAdmin', email)
  }

  setUserRole(role: string): void {
      this.userRole = role
      localStorage.setItem('userRole', role)
  }

  reStoreSuperAdmin(): void {
      const superAdmin = localStorage.getItem('superAdmin')
      if (superAdmin != null) {
          this.superAdmin = superAdmin
      }
  }

  setAuthToken(token: string): void {

      if (token) {
          this.authToken = token
          axios.defaults.headers.common['authorization'] = 'Bearer ' + token
          localStorage.setItem('authorization', token)

          // Apply token token to every request if logged in
          this.isUserLoggedIn = true
          this.isAdmin = ((localStorage.getItem('userRole') === 'SUPER_ADMIN') || (localStorage.getItem('userRole') === 'ADMIN_CMS_TEAM_IPAD'))
          this.isTeam = ((localStorage.getItem('userRole') === USER_ROLE_ADMIN_CMS) || (localStorage.getItem('userRole') === USER_ROLE_TEAM_CMS_IPAD))
          this.isDeedUser = localStorage.getItem('userRole') === USER_ROLE_DEED_ONLY
      } else {

          // Delete auth header
          delete axios.defaults.headers.common['authorization']
          localStorage.removeItem('authorization')
          this.isUserLoggedIn = false
      }
  }

  restoreToken(): void {
      const token = localStorage.getItem('authorization')

      if (token != null) {
          this.authToken = token
          axios.defaults.headers.common['authorization'] = 'Bearer ' + token
          const decoded: JwtDecoded = jwt_decode(token)

          // Check for expired token
          const currentTime = Date.now() / 1000

          if (decoded.exp < currentTime) {
              this.setAuthToken('')
              this.isUserLoggedIn = false
          } else {
              this.setAuthToken(token)
          }

      }
  }
}

export default AuthStore 