import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { Link } from 'react-router-dom'
import { Cell } from '../table/TableCellText.d'
import Modal from '../commonComponent/Modal'
import { UpworkJobLoader } from '../commonComponent/DotLoader'
import ReactJson from 'react-json-view'
import { FormateDate, isValueEquivalent } from '../commonComponent/commonFuction'
import { debounceSearch } from '../../utils/debounce'

interface Props {
    rootStore: RootStore,
}

const ErrorLogsDetailsPage: React.FC<Props> = ({ rootStore }) => {
    const { errorLogStore, authStore, setTitle } = rootStore
    const { isAdmin } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, errorLogCronData, fetchErrorLogCronData, getRequestData, requestData, getResponseData, responseData, isLoadingInfo, isPageOpened } = errorLogStore
    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [showModal, setShow] = useState(false)
    const [selectedRow, setSelectedRow] = useState({ _id: '', action: '' })
    const loadPage = (page: number) => {
        fetchErrorLogCronData(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchErrorLogCronData(1, e.target.value.trim()))
    }

    const openCloseModal = () => {
        setShow(!showModal)
    }
    const onTableAction = (action: string, value: any, data: any) => {
        setSelectedRow({ _id: data._id, action: action })
        getRequestData(data._id)
        openCloseModal()
    }

    const onTableResponseAction = (action: string, value: any, data: any) => {
        setSelectedRow({ _id: data._id, action: action })
        getResponseData(data._id)
        openCloseModal()
    }

    const TableRequestDataDetails: React.FC<Cell> = (props) => {
        const value = props.data._id || ''
        const data = props.data || {}

        return (
            <>
                <Link to='#' data-target='#myModal' className='mr-2' onClick={() => {
                    if (onTableAction != null) {
                        onTableAction('viewRequestData', value, data)
                    }
                }}>View Request Data</Link>

                {showModal && isValueEquivalent({ _id: data._id, action: 'viewRequestData' }, selectedRow) ? (
                    <Modal show={showModal}
                        modalTitle={data.user_id[0].name}
                        openCloseModal={openCloseModal}
                        modalDialogClass='modal-lg'
                        cancelButtonName='Close'
                        isScrollable={true}
                        cancelButton={true}
                        isSaveButton={false}
                    >
                        {isLoadingInfo ? <UpworkJobLoader /> : <>
                            {requestData && <div>
                                <ReactJson src={requestData} />
                            </div>}
                        </>
                        }
                    </Modal>) : null
                }
            </>
        )
    }

    const TableResponseDataDetails: React.FC<Cell> = (props) => {
        const value = props.data._id || ''
        const data = props.data || {}
        return (
            <>
                <Link to='#' data-target='#myModal' className='mr-2' onClick={() => {
                    if (onTableResponseAction != null) {
                        onTableResponseAction('viewResponseData', value, data)
                    }
                }}>View Response Data</Link>

                {showModal && isValueEquivalent({ _id: data._id, action: 'viewResponseData' }, selectedRow) ? (
                    <Modal show={showModal}
                        modalTitle={data.user_id[0].name}
                        openCloseModal={openCloseModal}
                        modalDialogClass='modal-lg'
                        cancelButtonName='Close'
                        isScrollable={true}
                        cancelButton={true}
                        isSaveButton={false}
                    >
                        {isLoadingInfo ? <UpworkJobLoader /> : <>
                            
                            <ReactJson src={responseData} />
                            < hr />
                        </>
                        }
                    </Modal>) : null
                }
            </>
        )
    }

    useEffect(() => {
        setTitle('Error Logs | OathZephyr')
        if(isAdmin && !isPageOpened){
            fetchErrorLogCronData(1)
        }
    }, [setTitle])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Error Logs
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'user_id[0].name', title: 'Client Name' },
                                    { name: 'end_point', title: 'End Point' },
                                    { name: 'build_version', title: 'Build Version' },
                                    { name: 'createdAt', title: 'Created At', component: FormateDate },
                                    { name: 'request_data', title: 'Request Data', component: TableRequestDataDetails },
                                    { name: 'response_data', title: 'Response Data', component: TableResponseDataDetails }
                                ]}
                                data={errorLogCronData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={errorLogCronData.currentPage}
                            totalPages={errorLogCronData.totalPage}
                            totalItems={errorLogCronData.totalItem}
                            itemsPerPage={errorLogCronData.itemPerPage}
                            // isFiltered={errorLogCronData.isFiltered}
                            totalAllItems={errorLogCronData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(ErrorLogsDetailsPage)
