import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../store/types.d'
import classNames from 'classnames'
import { handleKeyDownWithOutSpace, onChangeEmail } from '../commonComponent/WithoutAcceptSpace'

const AddUpdateOrganizationPage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { organizationStore, setTitle } = rootStore
    const { isApiError, isLoading, apiErrorMessage, organizationDetails, setOrganizationDetails, resetOrganizationDetails, addUpdateOrganizationDetails } = organizationStore

    const history = useHistory()
    const pageName = (history.location.pathname.includes('add')) ? 'Add Organization' : 'Update Organization'
    const pathArrayWithStateId = history.location.pathname.split('/')

    const organizationId = (pageName === 'Update Organization') ? pathArrayWithStateId[3] : ''

    const [lmIntegration, setLMIntegration] = useState(false)
    const [convertkitIntegration, setConvertkitIntegration] = useState(false)
    const [sendgridIntegration, setSendgridIntegration] = useState(false)
    const [xeroIntegration, setXeroIntegration] = useState(false)

    useEffect(() => {
        if (pageName === 'Update Organization' && (organizationDetails._id === '' || organizationId !== organizationDetails._id)) {
            setOrganizationDetails(organizationId)
        } else if(pageName === 'Add Organization' && organizationDetails._id !== '' ) {
            resetOrganizationDetails()
        }
        if(pageName === 'Update Organization' && organizationDetails._id !== '') {
            setLMIntegration(organizationDetails.lawmatics_integration)
            setConvertkitIntegration(organizationDetails.convertkit_integration)
            setSendgridIntegration(organizationDetails.sendgrid_integration)
            setXeroIntegration(organizationDetails.xero_integration)
        }
    }, [pageName, organizationId, resetOrganizationDetails, setOrganizationDetails, organizationDetails])

    useEffect(() => {
        if (pageName === 'Update Organization') {
            if(organizationId === organizationDetails._id){
                setTitle(`${organizationDetails.name} | Manage Organization`)
            }
        } else if(pageName === 'Add Organization') {
            setTitle('Plural | Add')
        }
    })

    const handleClick = () => {
        resetOrganizationDetails()
        history.push('/organizations/')
    }

    const onSubmit = async (data: Dictionary<string>) => {

        const requestData = {
            _id: organizationId,
            name: data.name,
            about: data.about,
            logo: '',
            lawmatics_integration: lmIntegration,
            lawmatics_configuration: {
                client_id: data.client_id,
                client_secret: data.client_secret,
                user_email: data.user_email,
                location_id: data.location_id,
                organization_code: data.organization_code,
                appointment_name: data.appointment_name,
                appointment_time: data.appointment_time
            },
            sendgrid_integration: sendgridIntegration,
            sendgrid_configuration: { 
                api_key: data.api_key,
                from_email: data.from_email,
                cc_email: data.cc_email
            },
            xero_integration: xeroIntegration,
            xero_configuration: {
                redirect_url: data.redirect_url,
                tenant_id: data.tenant_id,
                suspense_account_code: data.suspense_account_code,
                item_code: data.item_code,
                tracking_name: data.tracking_name,
                currency_code: data.currency_code,
                tax_type: data.tax_type,
                line_amount_type: data.line_amount_type,
                invoice_status: data.invoice_status,
            },
            convertkit_integration: convertkitIntegration,
            convertkit_configuration: {
                api_key: data.api_key_c,
                api_secret: data.api_secret,
                form_id_trustee_info: data.form_id_trustee_info,
                form_id_trustee_reminder: data.form_id_trustee_reminder,
                tag_id: data.tag_id
            }
        }

        const response = await addUpdateOrganizationDetails(requestData)
        if (response === 'true') {
            history.push('/organizations/')
        }
    }

    const handleChangeLMIntegration = (e: any) => {
        setLMIntegration(e.target.checked)
    }

    const handleChangeConvertkitIntegration = (e: any) => {
        setConvertkitIntegration(e.target.checked)
    }

    const handleChangeXeroIntegration = (e: any) => {
        setXeroIntegration(e.target.checked)
    }

    const handleChangeSendgridIntegration = (e: any) => {
        setSendgridIntegration(e.target.checked)
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Organizations', to: '/organizations/' },
            { name: (pageName === 'Update Organization') ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName}
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(organizationId === organizationDetails._id)
                            ? <form onSubmit={handleSubmit(onSubmit)} >
                                <fieldset>

                                    <InputField
                                        label='Name *'
                                        fieldName='name'
                                        register={register}
                                        errors={errors.singular}
                                        placeholder='Add your organization name'
                                        defaultValue={organizationDetails.name}
                                        required={true}
                                    />

                                    <InputField
                                        label='About'
                                        fieldName='about'
                                        register={register}
                                        errors={errors.plural}
                                        placeholder='Say something about organization'
                                        defaultValue={organizationDetails.about}
                                    />

                                    <div className="custom-control form-group custom-switch">
                                        <input type="checkbox" defaultChecked={organizationDetails.lawmatics_integration}
                                            ref={register}
                                            className="custom-control-input" name='lawmatics_integration' id="lawmaticsIntegration" onChange={handleChangeLMIntegration} />
                                        <label className="custom-control-label" htmlFor='lawmaticsIntegration'>Lawmatics Integration</label>
                                        {errors.lawmatics_integration && <div className='invalid-feedback'>{errors.lawmatics_integration.message}</div>}
                                    </div>

                                    <div className={classNames('card card-body', {'d-none': ( lmIntegration !== true) })}>
                                        <InputField
                                            label='Client ID *'
                                            fieldName='client_id'
                                            register={register}
                                            errors={errors.client_id}
                                            placeholder='Client ID'
                                            defaultValue={organizationDetails.lawmatics_configuration.client_id}
                                            required={lmIntegration}
                                        />
                                        <InputField
                                            label='Client Secret *'
                                            fieldName='client_secret'
                                            register={register}
                                            errors={errors.client_secret}
                                            placeholder='Client Secret'
                                            defaultValue={organizationDetails.lawmatics_configuration.client_secret}
                                            required={lmIntegration}
                                        />
                                        <InputField
                                            label='User Email *'
                                            fieldName='user_email'
                                            register={register}
                                            errors={errors.user_email}
                                            placeholder='User Email'
                                            defaultValue={organizationDetails.lawmatics_configuration.user_email}
                                            required={lmIntegration}
                                        />
                                        <InputField
                                            label='Location ID *'
                                            fieldName='location_id'
                                            register={register}
                                            errors={errors.location_id}
                                            placeholder='Location ID'
                                            defaultValue={organizationDetails.lawmatics_configuration.location_id}
                                            required={lmIntegration}
                                        />
                                        <InputField
                                            label='Lawmatics Organization Code *'
                                            fieldName='organization_code'
                                            register={register}
                                            errors={errors.organization_code}
                                            placeholder='Lawmatics Organization Code'
                                            defaultValue={organizationDetails.lawmatics_configuration.organization_code}
                                            required={lmIntegration}
                                        />
                                        <InputField
                                            label='Appointment Name *'
                                            fieldName='appointment_name'
                                            register={register}
                                            errors={errors.appointment_name}
                                            placeholder='Appointment Name'
                                            defaultValue={organizationDetails.lawmatics_configuration.appointment_name}
                                            required={lmIntegration}
                                        />
                                        <InputField
                                            label='Appointment Time *'
                                            fieldName='appointment_time'
                                            register={register}
                                            errors={errors.appointment_time}
                                            placeholder='Appointment Time'
                                            defaultValue={organizationDetails.lawmatics_configuration.appointment_time}
                                            required={lmIntegration}
                                        />
                                    </div>

                                    <div className="custom-control mt-3 form-group custom-switch">
                                        <input type="checkbox" defaultChecked={organizationDetails.sendgrid_integration}
                                            ref={register}
                                            className="custom-control-input" name='sendgrid_integration' id="sendgridIntegrationSwitch" onChange={handleChangeSendgridIntegration} />
                                        <label className="custom-control-label" htmlFor='sendgridIntegrationSwitch'>Sendgrid Integration</label>
                                        {errors.sendgrid_integration && <div className='invalid-feedback'>{errors.sendgrid_integration.message}</div>}
                                    </div>
 
                                    <div className={classNames('card card-body', {'d-none': ( sendgridIntegration !== true) })}>
                                        <InputField
                                            label='API Key *'
                                            fieldName='api_key'
                                            register={register}
                                            errors={errors.api_key}
                                            placeholder='API Key'
                                            defaultValue={organizationDetails.sendgrid_configuration.api_key}
                                            required={sendgridIntegration}
                                        />

                                        <div className="form-group">
                                            <label>From Email *</label>
                                            <input
                                                ref={register({
                                                    required: sendgridIntegration ? 'This field is required' : false,
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'Invalid email address'
                                                    }
                                                })}
                                                name='from_email'
                                                defaultValue={organizationDetails.sendgrid_configuration.from_email}
                                                type='text'
                                                onChange={onChangeEmail}
                                                onKeyDown={handleKeyDownWithOutSpace}
                                                className={classNames('form-control', { 'is-invalid': errors.from_email })}
                                                placeholder='Enter email'
                                            />
                                            {errors.from_email && (<div className='invalid-feedback'>{errors.from_email.message}</div>)}
                                        </div>
                                        <div className="form-group">
                                            <label>Email Added in CC for WAA and CRD</label>
                                            <input
                                                ref={register({
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'Invalid email address'
                                                    }
                                                })}
                                                name='cc_email'
                                                defaultValue={organizationDetails.sendgrid_configuration.cc_email}
                                                type='text'
                                                onChange={onChangeEmail}
                                                onKeyDown={handleKeyDownWithOutSpace}
                                                className={classNames('form-control', { 'is-invalid': errors.cc_email })}
                                                placeholder='Enter email'
                                            />
                                            {errors.cc_email && (<div className='invalid-feedback'>{errors.cc_email.message}</div>)}
                                        </div>
                                    </div>

                                    <div className="custom-control mt-3 form-group custom-switch">
                                        <input type="checkbox" defaultChecked={organizationDetails.xero_integration}
                                            ref={register}
                                            className="custom-control-input" name='xero_integration' id="xeroIntegrationSwitch" onChange={handleChangeXeroIntegration} />
                                        <label className="custom-control-label" htmlFor='xeroIntegrationSwitch'>Xero Integration</label>
                                        {errors.xero_integration && <div className='invalid-feedback'>{errors.xero_integration.message}</div>}
                                    </div>

                                    <div className={classNames('card card-body', {'d-none': ( xeroIntegration !== true) })}>
                                        <InputField
                                            label='Redirect URL *'
                                            fieldName='redirect_url'
                                            register={register}
                                            errors={errors.redirect_url}
                                            placeholder='Redirect URL'
                                            defaultValue={organizationDetails.xero_configuration.redirect_url}
                                            required={xeroIntegration}
                                        />
                                        <InputField
                                            label='Tenant ID *'
                                            fieldName='tenant_id'
                                            register={register}
                                            errors={errors.tenant_id}
                                            placeholder='Tenant ID'
                                            defaultValue={organizationDetails.xero_configuration.tenant_id}
                                            required={xeroIntegration}
                                        />
                                        <InputField
                                            label='Suspense Account Code *'
                                            fieldName='suspense_account_code'
                                            register={register}
                                            errors={errors.suspense_account_code}
                                            placeholder='Suspense Account Code'
                                            defaultValue={organizationDetails.xero_configuration.suspense_account_code}
                                            required={xeroIntegration}
                                        />
                                        <InputField
                                            label='Item Code *'
                                            fieldName='item_code'
                                            register={register}
                                            errors={errors.item_code}
                                            placeholder='Item Code'
                                            defaultValue={organizationDetails.xero_configuration.item_code}
                                            required={xeroIntegration}
                                        />
                                        <InputField
                                            label='Tracking Name *'
                                            fieldName='tracking_name'
                                            register={register}
                                            errors={errors.tracking_name}
                                            placeholder='Tracking Name'
                                            defaultValue={organizationDetails.xero_configuration.tracking_name}
                                            required={xeroIntegration}
                                        />
                                        <InputField
                                            label='Currency Code *'
                                            fieldName='currency_code'
                                            register={register}
                                            errors={errors.currency_code}
                                            placeholder='Currency Code'
                                            defaultValue={organizationDetails.xero_configuration.currency_code}
                                            required={xeroIntegration}
                                        />
                                        <InputField
                                            label='Tax Type *'
                                            fieldName='tax_type'
                                            register={register}
                                            errors={errors.tax_type}
                                            placeholder='Tax Type'
                                            defaultValue={organizationDetails.xero_configuration.tax_type}
                                            required={xeroIntegration}
                                        />
                                        <InputField
                                            label='Line Amount Type *'
                                            fieldName='line_amount_type'
                                            register={register}
                                            errors={errors.line_amount_type}
                                            placeholder='Line Amount Type'
                                            defaultValue={organizationDetails.xero_configuration.line_amount_type}
                                            required={xeroIntegration}
                                        />
                                        <InputField
                                            label='Invoice Status *'
                                            fieldName='invoice_status'
                                            register={register}
                                            errors={errors.invoice_status}
                                            placeholder='Invoice Status'
                                            defaultValue={organizationDetails.xero_configuration.invoice_status}
                                            required={xeroIntegration}
                                        />
                                    </div>

                                    <div className="custom-control mt-3 form-group custom-switch">
                                        <input type="checkbox" defaultChecked={organizationDetails.convertkit_integration}
                                            ref={register}
                                            className="custom-control-input" name='convertkit_integration' id="convertkitIntegrationSwitch" onChange={handleChangeConvertkitIntegration} />
                                        <label className="custom-control-label" htmlFor='convertkitIntegrationSwitch'>Convertkit Integration</label>
                                        {errors.convertkit_integration && <div className='invalid-feedback'>{errors.convertkit_integration.message}</div>}
                                    </div>

                                    <div className={classNames('card card-body', {'d-none': ( convertkitIntegration !== true) })}>
                                        <InputField
                                            label='API Key *'
                                            fieldName='api_key_c'
                                            register={register}
                                            errors={errors.api_key_c}
                                            placeholder='API Key'
                                            defaultValue={organizationDetails.convertkit_configuration.api_key}
                                            required={convertkitIntegration}
                                        />
                                        <InputField
                                            label='API Secret *'
                                            fieldName='api_secret'
                                            register={register}
                                            errors={errors.api_secret}
                                            placeholder='API Secret'
                                            defaultValue={organizationDetails.convertkit_configuration.api_secret}
                                            required={convertkitIntegration}
                                        />
                                        <InputField
                                            label='Form ID For Trustee Info *'
                                            fieldName='form_id_trustee_info'
                                            register={register}
                                            errors={errors.form_id_trustee_info}
                                            placeholder='Form ID'
                                            defaultValue={organizationDetails.convertkit_configuration.form_id_trustee_info}
                                            required={convertkitIntegration}
                                        />
                                        <InputField
                                            label='Form ID For Trustee Reminder *'
                                            fieldName='form_id_trustee_reminder'
                                            register={register}
                                            errors={errors.form_id_trustee_reminder}
                                            placeholder='Form ID'
                                            defaultValue={organizationDetails.convertkit_configuration.form_id_trustee_reminder}
                                            required={convertkitIntegration}
                                        />
                                        <InputField
                                            label='Tag ID *'
                                            fieldName='tag_id'
                                            register={register}
                                            errors={errors.tag_id}
                                            placeholder='Tag ID'
                                            defaultValue={organizationDetails.convertkit_configuration.tag_id}
                                            required={convertkitIntegration}
                                        />
                                    </div>

                                    <div className='form-group mt-4'>
                                        <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>&nbsp;&nbsp;
                                        <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                    </div>
                                </fieldset>
                            </form>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdateOrganizationPage)