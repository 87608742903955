import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { debounceSearch } from '../../utils/debounce'
import { TableCellCheckboxCompleted, TableCellCheckboxDrafted, TableCellCheckboxPending, TableCellCheckboxReview, TableCellCheckboxWorking, TableCellCreatedDetails } from './TableCell'
import { Cell } from '../table/TableCellText.d'
import { Link } from 'react-router-dom'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import { OPTION_LIST } from '../../../src/utils/constant'

interface Props {
    rootStore: RootStore,
}

const TableCellMatterName: React.FC<Cell> = (props) => {
    const { client_id, matter_id, matter_name } = props.data
    return (
        <>
            <Link to={`/clients/${client_id}/matters/${matter_id}`} >{matter_name}</Link>
        </>
    )
}

const ClientDraftAdjustmentPage: React.FC<Props> = ({ rootStore }) => {
    const optionList = OPTION_LIST
    const { clientDraftAdjustmentStore, authStore, setTitle } = rootStore
    const { isTeam, isAdmin } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, clientDraftAdjustment, fetchClientDraftAdjustmentData, isPageOpened, filterWithFutureSigning, filterByStatus } = clientDraftAdjustmentStore
    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [searchStatus, setSearchStatus] = useState(filterByStatus)
    const [filterWithFutureSigningValue, setFilterWithFutureSigning] = useState(filterWithFutureSigning)

    console.log(searchStatus)
    const loadPage = (page: number) => {
        fetchClientDraftAdjustmentData(page, searchItem, searchStatus, filterWithFutureSigning)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchClientDraftAdjustmentData(1, e.target.value.trim(), searchStatus, filterWithFutureSigning))
    }

    useEffect(() => {
        if ((isAdmin || isTeam) && !isPageOpened) {
            fetchClientDraftAdjustmentData(1, '', [{ value: 'is_not_completed', label: 'Not Completed' }], true)
        }
    }, [])

    useEffect(() => {
        setTitle('Client Draft Adjustments | OathZephyr')
    }, [setTitle])

    const isOptionDisabled = (option) => {
        const value = option.value.split('_').pop()
        if (searchStatus.some(item => item.value.includes(value))) {
            return true
        }
        return false
    }

    const handleOnChangeStatus = (data: any) => {
        setSearchStatus(data)
        fetchClientDraftAdjustmentData(1, searchItem, data, filterWithFutureSigning)
    }

    const handleFilterWithFutureSinging = (e: any) => {
        const value = e.target.checked
        setFilterWithFutureSigning(value)
        fetchClientDraftAdjustmentData(1, searchItem, searchStatus, value)
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Client Draft Adjustments
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-4 d-flex'>
                                <label className='pr-0 mt-2'>Filter by Status:</label>
                                <div className='flex-grow-1 pl-2'>
                                    <ReactSelectComponent
                                        isSearchable={true}
                                        options={optionList}
                                        isMulti={true}
                                        value={searchStatus}
                                        handleOnChangeReact={handleOnChangeStatus}
                                        placeholder='Select...'
                                        isOptionDisabled={isOptionDisabled}
                                    />
                                </div>
                            </div>
                            <div className='col-auto ml-3 pt-2 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='filterWithNote' id="futureSigningFilter" onClick={handleFilterWithFutureSinging} defaultChecked={filterWithFutureSigningValue} />
                                <label className="custom-control-label pr-2" htmlFor='futureSigningFilter'>Future Signing Dates Only</label>
                            </div>
                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive table-sm'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'is_working', title: 'Working', component: TableCellCheckboxWorking },
                                    { name: 'is_pending', title: 'Pending', component: TableCellCheckboxPending },
                                    { name: 'is_drafted', title: 'Drafted', component: TableCellCheckboxDrafted },
                                    { name: 'is_review', title: 'Reviewed', component: TableCellCheckboxReview },
                                    { name: 'is_completed', title: 'Completed', component: TableCellCheckboxCompleted },
                                    { name: 'matter_name', title: 'Matter', component: TableCellMatterName },
                                    { name: 'signing_date', title: 'Signing Date' },
                                    { name: 'note', class:'wrap-cell', title: 'Requested Adjustment' },
                                    { name: 'created_by', title: 'Created', component: TableCellCreatedDetails }
                                ]}
                                data={clientDraftAdjustment.page_items.map((item) => {
                                    return { ...item, clientDraftAdjustmentStore }
                                })}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={clientDraftAdjustment.currentPage}
                            totalPages={clientDraftAdjustment.totalPage}
                            totalItems={clientDraftAdjustment.totalItem}
                            itemsPerPage={clientDraftAdjustment.itemPerPage}
                            isFiltered={clientDraftAdjustment.isFiltered}
                            totalAllItems={clientDraftAdjustment.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(ClientDraftAdjustmentPage)
