import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { Cell } from '../table/TableCellText.d'
import { TableCellMatterName, TableCellNote, TableCellNoteAddedBy } from './TableCellForMatterModule'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import { SortConfig } from '../../store/types.d'
import uuid from 'react-uuid'
import { defaultAllObj } from '../../store/InitialValues'
import { debounceSearch } from '../../utils/debounce'
import { TableCellTags } from '../tag/TableCellTag'

interface Props {
    rootStore: RootStore,
}

const TableCellCheckbox: React.FC<Cell> = (props) => {
    const defaultValue = props.data.is_completed || false

    const onChange = (e) => {
        e.preventDefault()
        if (confirm('Are you sure want to mark matter completed ?')) {
            props.data.matterStore.markCompleted(props.data._id, !defaultValue)
        }
    }

    return (
        <>
            {props.data.note !== '' && props.data.should_we_need_additional_info == false ? <input key={uuid()} type='checkbox' checked={defaultValue} onChange={onChange} /> : ''}
        </>
    )
}

const TableCellCheckboxInformation: React.FC<Cell> = (props) => {
    const value = props.value
    const defaultValue = !props.data.should_we_need_additional_info
    const onChange = (e) => {
        e.preventDefault()
        if (confirm('Are you sure you collect all the information from client ?')) {
            props.data.matterStore.markNeedInfo(value, !props.data.should_we_need_additional_info)
        }
    }

    return (
        (props.data.should_we_need_additional_info) ? (<>
            <input type='checkbox' key={value + 'check'} checked={defaultValue} onChange={onChange} />
        </>) : <></>
    )
}


const optionOfMatterType = [
    defaultAllObj, {
        label: 'Individual',
        value: 'INDIVIDUAL'
    }, {
        label: 'Individual Married',
        value: 'INDIVIDUAL_MARRIED'
    }, {
        label: 'Joint',
        value: 'JOINT'
    }]




const MatterPage: React.FC<Props> = ({ rootStore }) => {
    const { matterStore, allStatesStore, allPackagesStore, tagStore, allTagStore, setTitle } = rootStore

    const { isApiError, isLoading, isPageOpened, apiErrorMessage, matterData, fetchMatters, searchItem, tableRows, filterWithNote, page, filterWithFutureSigning, filterWithNeededInfo, filterWithExcludeMatterData, sortMatterData, searchState, searchPackage, searchType } = matterStore
    const { stateNamesAndCodesWithAllAsOption } = allStatesStore
    const { packagesWithAllValueLabel } = allPackagesStore
    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [filterWithNoteValue, setFilterWithNoteValue] = useState(filterWithNote)
    const [filterWithFutureSigningValue, setFilterWithFutureSigning] = useState(filterWithFutureSigning)
    const [filterWithNeededInfoValue, setFilterWithNeededInfo] = useState(filterWithNeededInfo)
    const [filterWithExcludeTestMatterValue, setFilterWithExcludeTestMatterValue] = useState(filterWithExcludeMatterData)

    const initialType = optionOfMatterType.find((item) => item.value === searchType) || defaultAllObj
    const initialPackage = packagesWithAllValueLabel.find((item) => item.value === searchPackage) || defaultAllObj
    const initialState = stateNamesAndCodesWithAllAsOption.find((item) => item.value === searchState) || {
        label: 'All',
        value: 'all'
    }


    const [searchByType, setSearchType] = useState(initialType)
    const [searchByPackage, setSearchPackage] = useState(initialPackage)
    const [searchByState, setSearchState] = useState(initialState)

    const loadPage = (page: number) => {
        fetchMatters(page, searchItem, filterWithNote, filterWithFutureSigning, filterWithNeededInfo, searchByType.value, searchByPackage.value, searchByState.value, filterWithExcludeTestMatterValue)
    }

    const handleFilterWithNote = (e: any) => {
        const value = e.target.checked
        setFilterWithNoteValue(value)
        fetchMatters(page, searchItem, value, filterWithFutureSigning, filterWithNeededInfo, searchByType.value, searchByPackage.value, searchByState.value, filterWithExcludeTestMatterValue)
    }

    const onChangeSearchKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchMatters(1, e.target.value.trim(), filterWithNote, filterWithFutureSigning, filterWithNeededInfo, searchByType.value, searchByPackage.value, searchByState.value, filterWithExcludeTestMatterValue))
    }

    const shouldSort = (sortConfig: SortConfig) => {
        sortMatterData(sortConfig)
    }

    const handleFilterWithFutureSinging = (e: any) => {
        const value = e.target.checked
        setFilterWithFutureSigning(value)
        fetchMatters(page, searchItem, filterWithNote, value, filterWithNeededInfo, searchByType.value, searchByPackage.value, searchByState.value, filterWithExcludeTestMatterValue)
    }

    const handleFilterWithNeededInfo = (e: any) => {
        const value = e.target.checked
        setFilterWithNeededInfo(value)
        fetchMatters(page, searchItem, filterWithNote, filterWithFutureSigning, value, searchByType.value, searchByPackage.value, searchByState.value, filterWithExcludeTestMatterValue)
    }

    const handleFilterWithExcludeTestData = (e: any) => {
        const value = e.target.checked
        setFilterWithExcludeTestMatterValue(value)
        fetchMatters(page, searchItem, filterWithNote, filterWithFutureSigning, filterWithNeededInfo, searchByType.value, searchByPackage.value, searchByState.value, value)
    }

    const handleOnChangeType = (data: any) => {
        if (data !== null) {
            setSearchType(data)
            fetchMatters(page, searchItem, filterWithNote, filterWithFutureSigning, filterWithNeededInfo, data.value, searchByPackage.value, searchByState.value, filterWithExcludeTestMatterValue)
        }
    }

    const handleOnChangePackage = (data: any) => {
        if (data !== null) {
            setSearchPackage(data)
            fetchMatters(page, searchItem, filterWithNote, filterWithFutureSigning, filterWithNeededInfo, searchByType.value, data.value, searchByState.value, filterWithExcludeTestMatterValue)
        }
    }

    const handleOnChangeState = (data: any) => {
        if (data !== null) {
            setSearchState(data)
            fetchMatters(page, searchItem, filterWithNote, filterWithFutureSigning, filterWithNeededInfo, searchByType.value, searchByPackage.value, data.value, filterWithExcludeTestMatterValue)
        }
    }

    useEffect(() => {
        setTitle('Matters | OathZephyr')        
        allStatesStore.getAllStates()
        allPackagesStore.getAllPackageNames()
        allTagStore.getAllTags()
        if(!isPageOpened){
            fetchMatters(1, '', false, true, false, 'ALL', 'ALL', 'all', true)
        }
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Matters
                </div>
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-auto ml-3 pt-2 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='filterWithNote' id="noteFilter" onClick={handleFilterWithNote} defaultChecked={filterWithNoteValue} />
                                <label className="custom-control-label pr-2" htmlFor='noteFilter'>Matters With Notes Only</label>
                            </div>
                            <div className='col-auto ml-3 pt-2 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='filterWithNote' id="futureSigningFilter" onClick={handleFilterWithFutureSinging} defaultChecked={filterWithFutureSigningValue} />
                                <label className="custom-control-label pr-2" htmlFor='futureSigningFilter'>Future Signing Dates Only</label>
                            </div>
                            <div className='col-auto ml-3 pt-2 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='filterWithNote' id="neededInfo" onClick={handleFilterWithNeededInfo} defaultChecked={filterWithNeededInfoValue} />
                                <label className="custom-control-label pr-2" htmlFor='neededInfo'>Needed Info Only</label>
                            </div>
                            <div className='col-auto ml-3 pt-2 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='exclude_test_data' id="excludeTestData" onClick={handleFilterWithExcludeTestData} defaultChecked={filterWithExcludeTestMatterValue} />
                                <label className="custom-control-label pr-2" htmlFor='excludeTestData'>Exclude Test Matters</label>
                            </div>
                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={onChangeSearchKeyword} aria-label="Search" />
                            </div>
                        </div>

                        {rootStore.authStore.isAdmin && <div className='row mt-2'>
                            <label className='col-auto pr-1 pt-2'>Filter by Type:</label>
                            <div className='col-sm mb-4'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={optionOfMatterType}
                                    value={searchByType}
                                    handleOnChangeReact={handleOnChangeType}
                                />
                            </div>
                            <label className='col-auto pr-1 pt-2'>Filter by Package:</label>
                            <div className='col-sm mr-auto mb-4'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={packagesWithAllValueLabel}
                                    value={searchByPackage}
                                    handleOnChangeReact={handleOnChangePackage}
                                />
                            </div>
                            <label className='col-auto pr-1 pt-2'>Filter by State:</label>
                            <div className='col-sm mr-auto mb-4'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={stateNamesAndCodesWithAllAsOption}
                                    value={searchByState}
                                    handleOnChangeReact={handleOnChangeState}
                                />
                            </div>
                        </div>}

                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}
                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key={uuid()}
                                columns={[
                                    { name: 'is_completed', title: 'Complete', component: observer(TableCellCheckbox) },
                                    { name: '_id', title: 'Collect the Information from Client', component: observer(TableCellCheckboxInformation) },
                                    { name: 'modifier_name', title: 'Drafter' },
                                    { name: 'name', title: 'Matter Name', component: TableCellMatterName },
                                    { name: 'signing_date', title: 'Signing Date', sort: true },
                                    { name: 'user_associated_tags', title: 'Tags', component: observer(TableCellTags) },
                                    { name: 'note', title: 'Note', class: 'set-note', component: TableCellNote },
                                    { name: 'completed_by', title: 'Completed By' },
                                    { name: 'updatedAt', title: 'Updated At', sort: true },
                                    { name: 'note_added_by', title: 'Note Added By', component: TableCellNoteAddedBy },
                                    { name: 'additional_info_completed_by', title: 'Complete Info By' },
                                    { name: 'additional_info_completed_at', title: 'Complete Info At' },
                                    { name: 'completed_date', title: 'Completed At' }
                                ]}
                                shouldSort={shouldSort}
                                data={tableRows.map((item) => {
                                    return { ...item, matterStore, tagStore, allTagStore }
                                })}
                            ></Table>
                        </div>
                        <Pagination
                            currentPage={matterData.currentPage}
                            totalPages={matterData.totalPage}
                            totalItems={matterData.totalItem}
                            itemsPerPage={matterData.itemPerPage}
                            isFiltered={matterData.isFiltered}
                            totalAllItems={matterData.totalAllItems}
                            onItemClick={loadPage}

                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(MatterPage)
