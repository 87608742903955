import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Dictionary, Props } from '../../store/types.d'
import SingleTemplateStore from '../../store/SingleTemplateStore'
import ArticleStore from '../../store/Article'
import ParagraphStore from '../../store/Paragraph'
import SectionStore from '../../store/Section'
import FormModal from '../commonComponent/FormModal'
import classNames from 'classnames'
import ReactEditor from '../template/ReactEditor'
import parse from 'html-react-parser'
import { replaceSpaceWithHyphen, DeleteButton, handleOnDelete } from '../commonComponent/commonFuction'
import { Article } from '../commonComponent/DotLoader'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Preview } from '../commonComponent/PreviewStyle'
import uuid from 'react-uuid'
import moment from 'moment'
import _ from 'lodash'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import ImageUploader from '../commonComponent/ImageUploader'


interface DeleteProps {
    store: SingleTemplateStore | ArticleStore | SectionStore | undefined
    item: any
}

const initialParagraph = {
    name: '',
    description: '',
    body: '',
    edit_history: [],
    is_all_states: true,
    states: [],
    type: 'paragraph',
    keywords: []
}

interface EditIconProps {
    onClick: () => void
    title?: string
    id?: string
}

const EditIcon: React.FC<EditIconProps> = ({ onClick, title, id }) => {
    return <button
        type='button'
        id={id}
        onClick={onClick}
        className='btn btn-sm mr-1'
        title={title}><FontAwesomeIcon icon={faEdit} /></button>
}

const AddParagraphButton: React.FC<EditIconProps> = ({ onClick, title }) => {
    return <div className="plus-circle text-center my-2">
        <button
            type="button"
            className="btn btn-sm btn-dark plus-button"
            onClick={onClick}
            title={title}>
            <span><FontAwesomeIcon icon={faPlusCircle} /></span>
        </button>
        <span className="add-para d-block ms-2">Add Paragraph</span>
    </div>
}

const UpdateHTMLPage: React.FC<Props> = ({ rootStore }) => {

    const { register, handleSubmit, errors } = useForm()
    const [shouldHideExtraFields, SetShouldHideExtraFields] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const currentUser = rootStore.superAdminStore.superAdminData._id || ''

    const { templateStore, clientStore, monacoEditorStore, debugStore, allUsersStore, setTitle, authStore, singleDocDeedImagesStore } = rootStore

    const { resetLoadingProcess, previewDocument, isLoading } = clientStore
    const { isDeedUser } = authStore
    const { templateDetails, isApiError, apiErrorMessage, getOneHTMLDocumentDetails } = templateStore
    const { allAppUsers } = allUsersStore

    const history = useHistory()

    const { clientId, matterId, documentId, spouse, UUID, isEdited } = useParams<{
        clientId: string,
        matterId: string,
        documentId: string,
        spouse: string,
        UUID: string,
        isEdited: string
    }>()

    const isHTMLDocument = (history.location.pathname.includes('/HTML')) ? true : false
    const [addEditStore, setAddEditStore] = useState<ArticleStore | SectionStore | SingleTemplateStore>()
    const [paragraph, setParagraph] = useState<ParagraphStore>()
    const [deleteItemWithStore, setDeleteItemWithStore] = useState<DeleteProps>()

    const [modalData, setModalData] = useState<ParagraphStore>(new ParagraphStore(initialParagraph))

    useEffect(() => {
        if ((templateDetails !== undefined && templateDetails.name === '') || (templateDetails !== undefined && documentId !== templateDetails._id)) {
            if (previewDocument._id === '' || previewDocument._id !== documentId) {
                const editedDocId = isEdited !== 'false' ? isEdited : ''
                const forSpouse = (spouse === 'true') ? true : false
                getOneHTMLDocumentDetails(matterId, documentId, forSpouse, UUID, editedDocId)
            }
            templateStore.setPreviewDocumentInTemplateDetails()
        }
        if (templateDetails.slug !== '' && singleDocDeedImagesStore.isImagesFetched === true) {
            singleDocDeedImagesStore.resetImages()
            singleDocDeedImagesStore.setImageFetchedFlag(false)
        }
    }, [matterId, resetLoadingProcess, templateDetails, documentId, isHTMLDocument, previewDocument._id, spouse, isEdited, getOneHTMLDocumentDetails, UUID, debugStore, templateStore, singleDocDeedImagesStore])

    useEffect(() => {
        allUsersStore.getAllUsers()
        if(templateDetails._id === documentId){
            setTitle(`${templateDetails.name} | Edit`)
        }
    }, [])

    const openCloseModal = () => {
        const show = (modalShow === false) ? true : false
        setModalShow(show)
    }

    const onActionClicked = (action: string, store: any, data: any) => {

        setDeleteItemWithStore({ item: data, store: store })

        if (action === 'addParagraph') {
            setModalData(new ParagraphStore({ ...initialParagraph }))
            setModalTitle('Add Paragraph')
            SetShouldHideExtraFields(false)
            openCloseModal()
            setAddEditStore(data)
        }
        if (action === 'editParagraph') {
            const editPara = {
                name: data.name,
                description: data.description,
                keywords: data.keywords,
                body: data.body,
                edit_history: data.edit_history || undefined,
                type: data.type,
                states: data.states,
                is_all_states: data.is_all_states
            }
            setModalData(new ParagraphStore(editPara))
            setModalTitle('Edit Paragraph')
            openCloseModal()
            setParagraph(data)
            SetShouldHideExtraFields(true)
        }
    }

    const onSaveModelForAddArticle = (data: Dictionary<string>) => {

        const editedHistory = modalData.edit_history || [] // modalData.edit_history.push({ edited_by: currentUser, edited_at: new Date() }
        editedHistory.push({ edited_by: currentUser, edited_at: new Date().toISOString() })
        if (modalTitle === 'Add Paragraph' && addEditStore !== undefined && modalData !== undefined) {

            addEditStore.addNewParagraph(modalData.name, modalData.description, modalData.body || '', editedHistory)
        }
        if (modalTitle === 'Edit Paragraph' && paragraph !== undefined) {
            paragraph.name = modalData?.name || ''
            paragraph.description = modalData?.description || ''
            paragraph.body = modalData?.body || ''
            paragraph.edit_history = editedHistory
        }

        setDeleteItemWithStore({ item: {}, store: undefined })
        openCloseModal()
    }

    const articleModalWithChildModal = () => {

        const handleOnChangeName = (e: any) => {
            modalData.name = e.target.value.trim()
            setModalData(modalData)
        }

        const handleOnChangeDescription = (e: any) => {
            modalData.description = e.target.value.trim()
            setModalData(modalData)
        }

        const handleOnEditorChange = (data: any) => {
            modalData.body = data
            setModalData(modalData)
            // setBody(data)
        }

        const showHiddenFields = () => {
            SetShouldHideExtraFields(!shouldHideExtraFields)
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur()
            }
        }

        const popup_title = `${modalTitle}:  ${modalData?.name}`

        return <FormModal modalTitle={popup_title} modalDialogClass='modal-fullscreen' show={modalShow} openCloseModal={openCloseModal}>
            <>
                <form onSubmit={handleSubmit(onSaveModelForAddArticle)} className='modal-body'>
                    <Preview>
                        <div className='row'>
                            <div className='col'>
                                <button className='btn btn-outline-primary' type='button' onClick={showHiddenFields}> {shouldHideExtraFields ? 'Show' : 'Hide' } Hidden Fields </button>
                            </div>
                            <div className='col text-right'>
                                <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary mr-1">Update</button>
                            </div>
                        </div>
                        <fieldset>
                            <div className={classNames('form-group', { 'd-none': shouldHideExtraFields })}>
                                <label className='mb-0 mt-2'>Title *</label>
                                <input type='text'
                                    className={classNames('form-control', { 'is-invalid': errors.name })}
                                    defaultValue={modalData?.name}
                                    ref={register({
                                        required: 'This field is required',
                                        validate: (value) => { return !!value.trim() }
                                    })}
                                    onChange={handleOnChangeName}
                                    name='name' />
                                {errors.name && (<div className='invalid-feedback'>{errors.name.message}</div>)}
                            </div>
                            <div className={classNames('form-group', { 'd-none': shouldHideExtraFields })}>
                                <label className='mb-0 mt-2'>Description</label>
                                <textarea className='form-control' defaultValue={modalData?.description} ref={register} onChange={handleOnChangeDescription} name='description' />
                            </div>
                            {(modalTitle === 'Add Paragraph' || modalTitle === 'Edit Paragraph') ?
                                <div className='form-group'>
                                    <ReactEditor store={monacoEditorStore} enableSideBySideView={true} content={modalData?.body} onEditorChange={handleOnEditorChange} isSimpleEditor={true} />
                                </div> : null}
                            <div className='row mt-4'>
                                {(modalTitle.includes('Edit')) ?
                                    <div className='col-auto pt-2'>
                                        <DeleteButton targetStore={deleteItemWithStore?.store} onClick={handleOnDelete} openCloseModal={openCloseModal} removeItem={deleteItemWithStore?.item} />
                                    </div> : null}
                                <div className='ml-auto'>
                                    <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseModal}>Cancel</button>
                                    <button type="submit" className="btn btn-outline-primary mr-3">Update</button>
                                </div>
                            </div>
                        </fieldset>
                    </Preview>
                </form >
            </>
        </FormModal >
    }

    const paragraphFunction = (paraDetails: ParagraphStore, store: any) => {
        const uuidForId = uuid()
        const  edit_history = _.get(paraDetails, 'edit_history', [])
        const { name, body } = paraDetails
        const  htmlID =  replaceSpaceWithHyphen(name)

        return <div className="card mb-3" style={{ backgroundColor: (edit_history.length > 0) ? 'lightyellow' : '' }} id={htmlID} key={uuid()}>
            <div className='card-body'>
                <div className='card-title'>
                    <table width='100%'>
                        <tr>
                            <td className='font-weight-bold'>{name}</td>
                            <td className='text-right'><EditIcon key={uuid()} id={uuidForId} onClick={() => { onActionClicked('editParagraph', store, paraDetails) }} /></td>
                        </tr>
                    </table>
                    <hr />
                </div>
                <div className='paragraph'>
                    <Preview key={uuid()}>
                        {parse(body)}
                    </Preview>
                </div>
            </div>
            {edit_history.length > 0  && <ReactTooltip anchorId={uuidForId} style={{ backgroundColor: 'rgb(222, 222, 222, 0.7)', color: '#222' }} >
                {edit_history.map((detail) => {
                    const user = allAppUsers.find((data) => data._id?.toString() === detail.edited_by.toString())
                    if (user !== undefined) {
                        return <div key={uuid()}>{moment(detail.edited_at).format('MM/DD/YYYY hh:mm a')} - {user.first_name} {user.last_name}</div>
                    }
                })
                }
            </ReactTooltip>
            }
        </div >
    }

    const deedUserLinks = [
        { name: 'Documents', to: `/clients/${clientId}/matters/${matterId}` },
        { name: 'HTML Editor', isActive: true }
    ]

    const adminSpecificLinks = [
        { name: 'Clients', to: '/clients/' },
        { name: 'Matters', to: `/clients/${clientId}` }
    ]

    const allLink = [...adminSpecificLinks, ...deedUserLinks]
    const links = isDeedUser ? deedUserLinks : allLink

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={links} />
        <div className="card" style={{ width: '794px' }}>
            <div className="card-header">
                Update HTML Document
            </div>
            {isLoading === true || templateStore.isLoading === true ? <Article /> : <div className="card-body p-1">
                {!isLoading && isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                    {apiErrorMessage}
                </div>}
                <div className="main-left-content">
                    {(documentId === templateDetails._id)
                        ? <div>
                            <div className="row">
                                <div className="col-12">
                                    {(templateDetails.content.length !== 0) ?
                                        templateDetails.content.map((list: any) => {
                                            if (list.type === 'article') {
                                                return <React.Fragment key={uuid()}>
                                                    <div className="card mb-3" key={uuid()} id={replaceSpaceWithHyphen(list.name)}>
                                                        <div className="card-body px-1 pt-2">
                                                            <h5 className='card-title'>{list.name}</h5>
                                                            {(list.content) ? list.content.map((inArticle: any) => {
                                                                if (inArticle.type === 'paragraph') {
                                                                    return paragraphFunction(inArticle, list)
                                                                } else if (inArticle.type === 'section') {
                                                                    return <React.Fragment key={uuid()}>
                                                                        <div className="card mb-3">
                                                                            <div className="card-body px-1 pt-2">
                                                                                <h5 key={uuid()} className='card-title'>{inArticle.name}</h5>
                                                                                {(inArticle.content) ? inArticle.content.map((inSection: any) => {
                                                                                    return paragraphFunction(inSection, inArticle)
                                                                                }) : null}
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                } else {
                                                                    return null
                                                                }
                                                            }) : null}
                                                            <AddParagraphButton key={uuid()} onClick={() => {
                                                                onActionClicked('addParagraph', {}, list)
                                                            }} title='Add new paragraph in article' />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            } else if (list.type === 'paragraph') {
                                                return paragraphFunction(list, templateDetails)
                                            } else {
                                                return null
                                            }
                                        }) : null}
                                </div>
                            </div>
                            {templateDetails.slug && templateDetails.slug.includes('deed') && <ImageUploader UUID={UUID} rootStore={rootStore} />}
                            <AddParagraphButton onClick={() => {
                                onActionClicked('addParagraph', {}, templateDetails)
                            }} title='Add new paragraph in template' />
                        </div>
                        : null}
                    {modalShow ? articleModalWithChildModal() : null}
                </div>
            </div>}
        </div>
    </Container>
}

export default observer(UpdateHTMLPage)

