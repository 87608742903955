import { observable } from 'mobx'
import RootStore from './Root'
import { getLawmaticsCronData} from '../api/ConnectToLamaticsActions'
import { LawmaticsCronInformation } from './types.d'
import { initialTableDataWithPaginationDetails } from './InitialValues'

interface LawmaticsData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<LawmaticsCronInformation>
}

class LawmaticsStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public lawmaticsCronData: LawmaticsData
    @observable public searchItem: string
    @observable public page: number
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.lawmaticsCronData = initialTableDataWithPaginationDetails
    }


    //get the list of lawmatics data with details
    fetchLawmaticsCronData = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.searchItem = searchItem
        this.page = page

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem }

        getLawmaticsCronData(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.lawmaticsCronData = response.data.data
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

}

export default LawmaticsStore
