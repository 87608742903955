import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import TableComp from '../table/Table'
import Pagination from '../table/Pagination'
import { Cell } from '../table/TableCellText.d'
import { Link } from 'react-router-dom'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import _ from 'lodash'
import uuid from 'react-uuid'
import { initialTemplate } from '../../store/InitialValues'
import Card from './CardViewComponent'
import classNames from 'classnames'
import { CardHeading, FileEarmarkArrowUpFill, Table } from 'react-bootstrap-icons'
import { debounceSearch } from '../../utils/debounce'
import { ValueLabel } from '../../store/types.d'
import Modal from '../commonComponent/Modal'
import { dateFormatWithTime } from '../commonComponent/commonFuction'
import FormModal from '../commonComponent/FormModal'
import { useForm } from 'react-hook-form'
import { FormateDateWithTime } from '../commonComponent/TableCell'
import DeedResearchFilesUploader from './DeedResearchImageUploader'

interface Props {
    rootStore: RootStore,
}

const TableCellMatterName: React.FC<Cell> = (props) => {
    const { client_id, matter_id, matter_name, document_id, linkInfo } = props.data

    return (
        <>
            <Link to={`/clients/${client_id}/matters/${matter_id}`} >{matter_name}</Link>
            { !_.isUndefined(document_id) ? 
                <>
                    <br/>
                    <Link to={linkInfo.link} className='mr-2' key={uuid()}>{linkInfo.label}</Link>
                </>
                : null 
            }
        </>
    )
}

const TableCellCheckboxCompleted: React.FC<Cell> = (props) => {
    const value = props.value
    const defaultValue = props.data.is_completed || false
    let isWorking = props.data.is_working || false

    const onChange = () => {
        if (!defaultValue) {
            isWorking = false
        }
        props.data.deedResearchStore.markCompleted(value, !defaultValue, isWorking)
    }

    return (
        <input type='checkbox' className='align-middle' key={value + 'complete'} checked={defaultValue} onChange={onChange} />
    )
}

export const TableCellCheckboxWorking: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_working || false
    const { working_by, working_date } = props.data
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const openCloseConfirmationModal = () => {
        setShowConfirmationModal(!showConfirmationModal)
    }
    const onSaveModal = () => {
        props.data.deedResearchStore.markWorking(value, !defaultValue)
        showConfirmationModal && openCloseConfirmationModal()
    }

    return (<>
        <input 
            type='checkbox' 
            className='align-middle' 
            key={value + 'working'} 
            checked={defaultValue} 
            onChange={() => {
                (!defaultValue && !_.isEmpty(props.data.working_by) ? setShowConfirmationModal(!showConfirmationModal) : onSaveModal()
                )
            }} 
        />

        {showConfirmationModal ? (
            <Modal show={showConfirmationModal} modalTitle='Confirm' openCloseModal=
                {openCloseConfirmationModal} onSaveModal={onSaveModal} saveButtonName='Confirm' modalDialogClass='modal-dialog-centered' >
                <div className='text-wrap'>
                    Are you sure you want to Check Working ? <br/>
                    Last Worked by <b>{ working_by } </b> at <b>{ dateFormatWithTime(working_date) }</b>
                </div>
            </Modal>
        ) : null}
    </>)
}

const TableCellCheckboxPending: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_pending || false
    const defaultNote = props.data.pending_notes || ''
    let isWorking = props.data.is_working || false
    const { register, handleSubmit, errors } = useForm()
    const [showNoteModal, setShowNoteModal] = useState(false)
    const scrollableBodyStyle = 'modal-body resource'

    const onChange = () => {
        if (!defaultValue) {
            isWorking = false
        }
        props.data.deedResearchStore.markPending(value, !defaultValue, defaultNote, isWorking, false)
    }
    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (data.note !== defaultNote) {
            isNoteChanged = true
        }
        if (data.note != null) {
            props.data.deedResearchStore.markPending(value, defaultValue, data.note, isWorking, isNoteChanged)
        }
        openCloseNoteModal()
    }

    return (
        <>
            {showNoteModal ? (
                <><FormModal show={showNoteModal} modalTitle={`Edit Pending Note - ${props.data.matter_name}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered' includeHeader={true}>
                    <form onSubmit={handleSubmit(onSaveNote)}>
                        <fieldset>
                            <div className={scrollableBodyStyle}>
                                <div className='form-group'>
                                    <textarea
                                        ref={register}
                                        name='note'
                                        defaultValue={defaultNote}
                                        style={{height:'300px'}}
                                        className={classNames('form-control', { 'is-invalid': errors.note })} />
                                    {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary">Update</button>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>
                </>) : null}
            <input type='checkbox' className='align-middle' key={value + 'pending'} checked={defaultValue} onChange={onChange} /><br />
            {(defaultValue || defaultNote !== '') && <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)}>Notes</Link>}
        </>
    )
}

const TableCellCheckboxResearching: React.FC<Cell> = (props) => {
    const { _id = '', is_researching = false, researching_notes = '', matter_name = '' } = props.data

    const value = _id
    const defaultValue = is_researching
    const defaultNote = researching_notes

    const { register, handleSubmit, errors } = useForm()
    const [showNoteModal, setShowNoteModal] = useState(false)

    const onChange = () => {
        props.data.deedResearchStore.markResearching(value, !defaultValue, defaultNote, false)
    }
    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (data.note !== defaultNote) {
            isNoteChanged = true
        }
        if (data.note != null) {
            props.data.deedResearchStore.markResearching(value, defaultValue, data.note, isNoteChanged)
        }
        openCloseNoteModal()
    }

    return (
        <>
            {showNoteModal && <>
                <FormModal 
                    show={showNoteModal} 
                    modalTitle={`Edit Researching Note - ${matter_name}`}  
                    openCloseModal={openCloseNoteModal} 
                    modalDialogClass='modal-dialog-centered' 
                    includeHeader={true}
                >
                    <form onSubmit={handleSubmit(onSaveNote)}>
                        <fieldset>
                            <div className='modal-body resource'>
                                <div className='form-group'>
                                    <textarea
                                        ref={register}
                                        name='note'
                                        defaultValue={defaultNote}
                                        style={{height:'300px'}}
                                        className={classNames('form-control', { 'is-invalid': errors.note })} />
                                    {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary">Update</button>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>
            </>}

            <input 
                type='checkbox' 
                className='align-middle' 
                key={value + 'researching'} 
                checked={defaultValue} 
                onChange={onChange} 
            /><br />
            {(defaultValue || defaultNote !== '') 
            && <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)}>Notes</Link>}
        </>
    )
}

const TableCellCheckboxResearchCompleted: React.FC<Cell> = (props) => {
    const { _id = '', is_research_completed = false, research_completed_notes = '', matter_name = '', uuid, with_files } = props.data

    const value = _id
    const defaultValue = is_research_completed
    const defaultNote = research_completed_notes

    const { register, handleSubmit, errors } = useForm()
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [showUploaderModal, setShowUploaderModal] = useState(false)

    const onChange = () => {
        props.data.deedResearchStore.markResearchCompleted(value, !defaultValue, defaultNote, false)
    }
    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }

    const openCloseUploaderModal = () => {
        setShowUploaderModal(!showUploaderModal)
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (data.note !== defaultNote) {
            isNoteChanged = true
        }
        if (data.note != null) {
            props.data.deedResearchStore.markResearchCompleted(value, defaultValue, data.note, isNoteChanged)
        }
        openCloseNoteModal()
    }

    const imageUploader = (with_files === false) ? 'float-right text-secondary cursor-pointer fs-1' : 'float-right text-primary cursor-pointer fs-1'

    return (
        <>
            {showNoteModal && <>
                <FormModal 
                    show={showNoteModal} 
                    modalTitle={`Edit Research Completed Note - ${matter_name}`}  
                    openCloseModal={openCloseNoteModal} 
                    modalDialogClass='modal-dialog-centered' 
                    includeHeader={true}
                >
                    <form onSubmit={handleSubmit(onSaveNote)}>
                        <fieldset>
                            <div className='modal-body resource'>
                                <div className='form-group'>
                                    <textarea
                                        ref={register}
                                        name='note'
                                        defaultValue={defaultNote}
                                        style={{height:'300px'}}
                                        className={classNames('form-control', { 'is-invalid': errors.note })} />
                                    {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary">Update</button>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>
            </>}

            {showUploaderModal && <>
                <FormModal 
                    show={showUploaderModal} 
                    modalTitle={`Files For Deed - ${matter_name}`}  
                    openCloseModal={openCloseUploaderModal} 
                    modalDialogClass='modal-dialog-centered modal-lg' 
                    includeHeader={true}
                >
                    <DeedResearchFilesUploader rootStore={props.data.deedResearchStore.rootStore} UUID={uuid} />
                </FormModal>
            </>}
            <div className='row'>
                <div className='col'>
                    <input 
                        type='checkbox' 
                        className='align-middle' 
                        key={value + 'research_completed'} 
                        checked={defaultValue} 
                        onChange={onChange} 
                    /><br />
                    {(defaultValue || defaultNote !== '') && <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)}>Notes</Link>}
                </div>
                <div className='col file-icon'>
                    <div className={imageUploader} onClick={openCloseUploaderModal} title='Deed Research files'><FileEarmarkArrowUpFill /></div>
                </div>
            </div>
        </>
    )
}

const TableCellCheckboxDrafted: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_drafted || false
    const defaultNote = props.data.drafted_notes || ''
    let isWorking = props.data.is_working || false
    const { register, handleSubmit, errors } = useForm()
    const [showNoteModal, setShowNoteModal] = useState(false)
    const scrollableBodyStyle = 'modal-body resource'

    const onChange = () => {
        if (!defaultValue) {
            isWorking = false
        }
        props.data.deedResearchStore.markDrafted(value, !defaultValue, defaultNote, isWorking, false)
    }
    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }
    const handleOnClickNote = () => {
        const notes = prompt('Add/Update Notes?', defaultNote)
        
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (data.note !== defaultNote) {
            isNoteChanged = true
        }

        if (data.note != null) {
            props.data.deedResearchStore.markDrafted(value, defaultValue, data.note, isWorking, isNoteChanged)
        }
        openCloseNoteModal()
    }
    return (
        <>
            {showNoteModal ? (
                <><FormModal show={showNoteModal} modalTitle={`Edit Drafted Note - ${props.data.matter_name}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered' includeHeader={true}>
                    <form onSubmit={handleSubmit(onSaveNote)}>
                        <fieldset>
                            <div className={scrollableBodyStyle}>
                                <div className='form-group'>
                                    <textarea
                                        ref={register}
                                        name='note'
                                        defaultValue={defaultNote}
                                        style={{height:'300px'}}
                                        className={classNames('form-control', { 'is-invalid': errors.note })} />
                                    {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary">Update</button>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>
                </>) : null}
            <input type='checkbox' className='align-middle' key={value + 'drafted'} checked={defaultValue} onChange={onChange} /><br />
            {(defaultValue || defaultNote !== '') && <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)}>Notes</Link> }
        </>
    )
}

const TableCellCheckboxReviewed: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_reviewed || false
    const defaultNotes = props.data.reviewed_notes || ''
    let isWorking = props.data.is_working || false
    const { register, handleSubmit, errors } = useForm()
    const [showNoteModal, setShowNoteModal] = useState(false)
    const scrollableBodyStyle = 'modal-body resource'

    const onChange = () => {
        if (!defaultValue) {
            isWorking = false
        }
        props.data.deedResearchStore.markReviewed(value, !defaultValue, defaultNotes, isWorking, false)
    }
    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false

        if (data.note !== defaultNotes) {
            isNoteChanged = true
        }
        if (data.note != null) {
            props.data.deedResearchStore.markReviewed(value, defaultValue, data.note, isWorking, isNoteChanged)
        }
        openCloseNoteModal()
    }

    return (
        <>
            {showNoteModal ? (
                <><FormModal show={showNoteModal} modalTitle={`Edit Reviewed Note - ${props.data.matter_name}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered' includeHeader={true}>
                    <form onSubmit={handleSubmit(onSaveNote)}>
                        <fieldset>
                            <div className={scrollableBodyStyle}>
                                <div className='form-group'>
                                    <textarea
                                        ref={register}
                                        name='note'
                                        defaultValue={defaultNotes}
                                        style={{height:'300px'}}
                                        className={classNames('form-control', { 'is-invalid': errors.note })} />
                                    {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary">Update</button>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>
                </>) : null}
            <input type='checkbox' className='align-middle' key={value + 'reviewed'} checked={defaultValue} onChange={onChange} /><br />
            {(defaultValue || defaultNotes !== '') && <Link to='#' className={classNames({ 'text-secondary': (defaultNotes === '') }) } onClick={() => setShowNoteModal(!showNoteModal)}>Review</Link>}
        </>
    )
}

const TableCellCompletedDetails: React.FC<Cell> = (props) => {
    const completedBy = props.data.completed_by || ''
    const completedAt = props.data.completed_date || ''
    const details = <div>{completedBy}<br />{dateFormatWithTime(completedAt)}</div>

    return (
        <>{details}</>
    )
}

const TableCellWorkingDetails: React.FC<Cell> = (props) => {
    const workingBy = props.data.working_by || ''
    const workingAt = props.data.working_date || ''
    const details = <div>{workingBy}<br />{dateFormatWithTime(workingAt)}</div>

    return (
        <>{details}</>
    )
}

const TableCellReviewedDetails: React.FC<Cell> = (props) => {
    const reviewedBy = props.data.reviewed_by || ''
    const reviewedAt = props.data.reviewed_date || ''
    const details = <div>{reviewedBy}<br />{dateFormatWithTime(reviewedAt)}</div>

    return (
        <>{details}</>
    )
}

const TableCellPendingDetails: React.FC<Cell> = (props) => {
    const pendingBy = props.data.pending_by || ''
    const pendingAt = props.data.pending_date || ''
    const details = <div>{pendingBy}<br />{dateFormatWithTime(pendingAt)}</div>

    return (
        <>{details}</>
    )
}

const TableCellDraftedDetails: React.FC<Cell> = (props) => {
    const draftedBy = props.data.drafted_by || ''
    const draftedAt = props.data.drafted_date || ''
    const details = <div>{draftedBy}<br />{dateFormatWithTime(draftedAt)}</div>

    return (
        <>{details}</>
    )
}

const TableCellResearchingDetails: React.FC<Cell> = (props) => {
    const researchingBy = props.data.researching_by || ''
    const researchingAt = props.data.researching_date || ''
    const details = <div>{researchingBy}<br />{dateFormatWithTime(researchingAt)}</div>

    return (
        <>{details}</>
    )
}

const TableCellResearchCompletedDetails: React.FC<Cell> = (props) => {
    const researchCompletedBy = props.data.research_completed_by || ''
    const researchCompletedAt = props.data.research_completed_date || ''
    const details = <div>{researchCompletedBy}<br />{dateFormatWithTime(researchCompletedAt)}</div>

    return (
        <>{details}</>
    )
}

let optionList = [{
    label: 'Completed',
    value: 'is_completed',
    disabled: 'is_not_completed'
}, {
    label: 'Pending',
    value: 'is_pending',
    disabled: 'is_not_pending'
}, {
    label: 'Drafted',
    value: 'is_drafted',
    disabled: 'is_not_drafted'
}, {
    label: 'Review',
    value: 'is_reviewed',
    disabled: 'is_not_reviewed'
}, {
    label: 'Working',
    value: 'is_working',
    disabled: 'is_not_working'
}, {
    label: 'Researching',
    value: 'is_researching',
    disabled: 'is_not_researching'
}, {
    label: 'Research Completed',
    value: 'is_research_completed',
    disabled: 'is_not_research_completed'
}, {
    label: 'Not Completed',
    value: 'is_not_completed',
    disabled: 'is_completed'
}, {
    label: 'Not Pending',
    value: 'is_not_pending',
    disabled: 'is_pending'
}, {
    label: 'Not Drafted',
    value: 'is_not_drafted',
    disabled: 'is_drafted'
}, {
    label: 'Not Review',
    value: 'is_not_reviewed',
    disabled: 'is_reviewed'
}, {
    label: 'Not Working',
    value: 'is_not_working',
    disabled: 'is_working'
}, {
    label: 'Not Researching',
    value: 'is_not_researching',
    disabled: 'is_researching'
}, {
    label: 'Not Research Completed',
    value: 'is_not_research_completed',
    disabled: 'is_research_completed'
}, {
    label: 'With Files',
    value: 'with_files',
    disabled: 'with_not_files'
}, {
    label: 'Without Files',
    value: 'with_not_files',
    disabled: 'with_files'
}]

const TableCellPropertyDetails: React.FC<Cell> = (props) => {
    const state = props.data.state_of_property || ''
    const county = props.data.county_of_property || ''
    const street = props.data.street_number || ''

    const details = <div>{state}<br />{county}<br />{street}</div>

    return (
        <>{details}</>
    )
}

const DeedResearchPage: React.FC<Props> = ({ rootStore }) => {
    const { authStore, deedResearchStore, allStatesStore, clientStore, templateStore, setTitle, singleDocDeedImagesStore, stateStore } = rootStore
    const { generateNewPDF } = clientStore
    const { templateDetails } = templateStore
    const { isAdmin } = authStore
    const { deedsData, tableRows, fetchDeeds, searchItem, excludeTestDeeds, page, userId, fetchUserEmail, userEmailWithValueLabel, filterWithFutureSigning, isTableView, setIsTableView, isPageOpened, searchByStatus } = deedResearchStore
    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [filterWithFutureSigningValue, setFilterWithFutureSigning] = useState(filterWithFutureSigning)
    const all = { value: 'All', label: 'All' }
    const userEmailWithAll = userEmailWithValueLabel.map((state) => {
        return { value: state.value, label: state.label }
    })
    userEmailWithAll.splice(0, 0, all)
    const searchedUserId = userEmailWithAll.find((email: ValueLabel) => email.value === userId)
    const [searchByUser, setSearchByUser] = useState((searchedUserId === undefined) ? all : searchedUserId)
    const [excludeTestDeedsField, setExcludeTestDeedsField] = useState(excludeTestDeeds)

    useEffect(() => {
        setTitle('Deed Research | OathZephyr')
        fetchUserEmail()
        templateDetails.set(initialTemplate)
    }, [])
    const [searchStatus, setSearchStatus] = useState(searchByStatus)

    const loadPage = (page: number) => {
        fetchDeeds(page, searchItem, searchByUser.value, filterWithFutureSigning, searchStatus, excludeTestDeedsField)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchDeeds(1, e.target.value.trim(), searchByUser.value, filterWithFutureSigning, searchStatus, excludeTestDeedsField))
    }

    const handleFilterWithFutureSinging = (e: any) => {
        const value = e.target.checked
        setFilterWithFutureSigning(value)
        fetchDeeds(page, searchItem, searchByUser.value, value, searchStatus, excludeTestDeedsField)
    }
    
    useEffect(() => {
        if(!isPageOpened){
            allStatesStore.getAllStates()
            fetchDeeds(1, '', '', true, [], true)
        }
        if(singleDocDeedImagesStore.isImagesFetched === true) {
            singleDocDeedImagesStore.resetImages()
            singleDocDeedImagesStore.setImageFetchedFlag(false)
        }
    }, [isPageOpened, fetchDeeds, singleDocDeedImagesStore])

    const handleOnChangeStatus = (data: any) => {
        if (data !== null) {

            if(data.length == 0) {
                optionList =  optionList.map(item => {
                    item.isDisabled = false 
                    return item
                })
            } else if(data[0].value.includes('not')) {
                optionList =  optionList.map(item => {
                    item.isDisabled = true 
                    return item
                })
            } else if(!data[0].value.includes('not')) {
                optionList = optionList.map(item => {
                    if(item.value.includes('not')) {
                        item.isDisabled = true 
                    }
                    return item
                })
            }

            setSearchStatus(data)
            fetchDeeds(1, searchItem, searchByUser.value, filterWithFutureSigning, data, excludeTestDeedsField)
        }
    }

    const handleOnChangeUserEmail = (data: any) => {
        if (data !== null) {
            setSearchByUser(data)
            fetchDeeds(1, searchItem, data.value, filterWithFutureSigning, searchStatus, excludeTestDeedsField)
        }
    }

    const handleExcludeTestDeeds = (e: any) => {
        const value = e.target.checked
        setExcludeTestDeedsField(value)
        fetchDeeds(1, searchItem, searchByUser.value, filterWithFutureSigning, searchStatus, value)
    }

    const isOptionDisabled = (option: any) => {   
        const disabled = option.disabled
        if (searchStatus.some(item => item.value.includes(disabled))) {
            return true
        }
        return false
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Deeds
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-4 d-flex'>
                                <label className='pr-0 mt-2'>Filter by Status:</label>
                                <div className='flex-grow-1 pl-2'>
                                    <ReactSelectComponent
                                        isSearchable={true}
                                        options={optionList}
                                        isMulti={true}
                                        value={searchStatus}
                                        handleOnChangeReact={handleOnChangeStatus}
                                        placeholder='Select...'
                                        isOptionDisabled={isOptionDisabled}
                                    />
                                </div>
                            </div>
                            <div className='col-4 d-flex'>
                                <label className='pr-0 mt-2'>Filter by Market:</label>
                                <div className='flex-grow-1 pl-2'>
                                    <ReactSelectComponent
                                        isSearchable={true}
                                        options={userEmailWithAll}
                                        value={searchByUser}
                                        handleOnChangeReact={handleOnChangeUserEmail}
                                    />
                                </div>
                            </div>
                            <div role="group" className='ml-auto btn-group view-group'>
                                <button type="button"  className={classNames({ 'active-view': isTableView === 'table_view'}, 'btn btn-outline-secondary') } data-toggle="tooltip" title='Table' onClick={() => setIsTableView('table_view')}><Table size={18}/></button>
                                <button type="button" className={classNames({ 'active-view': isTableView === 'card_view'}, 'btn btn-outline-secondary') } data-toggle="tooltip" title='Card' onClick={() => setIsTableView('card_view')}><CardHeading size={18}/></button>
                            </div>
                            <div className='col-2 ml d-flex'>
                                <input className="form-control " name='search' defaultValue={searchItemField}  type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>
                        <div className='row my-4'>
                            <div className='col-auto ml-3 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='filterWithNote' id="noteFilter" onClick={handleFilterWithFutureSinging} defaultChecked={filterWithFutureSigningValue} />
                                <label className="custom-control-label pr-2" htmlFor='noteFilter'>Future Signing dates only</label>
                            </div>
                            {isAdmin ? <div className='col-auto custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='exclude_test_data' id="excludeTestDeeds" onClick={handleExcludeTestDeeds} defaultChecked={excludeTestDeedsField} />
                                <label className="custom-control-label pr-2" htmlFor='excludeTestDeeds'>Exclude Test Deeds</label>
                            </div> : null}
                            
                        </div>
                        
                        {((deedResearchStore.isApiError) && <div className='responsive alert-danger p-3 mb-4 rounded' >{deedResearchStore.apiErrorMessage}</div>)}
                        {((clientStore.isApiError) && <div className='responsive alert-danger p-3 mb-4 rounded' >{clientStore.apiErrorMessage}</div>)}
                        {isTableView === 'table_view' ? <div className='table-responsive table-sm'>
                            <TableComp
                                isLoading={deedResearchStore.isLoading || clientStore.isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'is_researching', title: 'Researching', component: TableCellCheckboxResearching },
                                    { name: 'is_research_completed', title: 'Research Completed', component: TableCellCheckboxResearchCompleted },
                                    { name: 'is_working', title: 'Working', component: TableCellCheckboxWorking },
                                    { name: 'is_pending', title: 'Pending', component: TableCellCheckboxPending },
                                    { name: 'is_drafted', title: 'Drafted', component: TableCellCheckboxDrafted },
                                    { name: 'is_reviewed', title: 'Review', component: TableCellCheckboxReviewed },
                                    { name: '_id', title: 'Completed', component: TableCellCheckboxCompleted },
                                    { name: 'matter_name', title: 'Matter Name', component: TableCellMatterName },
                                    { name: 'signing_date', title: 'Signing Date' },
                                    { name: 'researching_notes', class:'wrap-cell', title: 'Researching Notes' },
                                    { name: 'research_completed_notes', class:'wrap-cell', title: 'Research Completed Notes' },
                                    { name: 'pending_notes', class:'wrap-cell', title: 'Pending Notes' },
                                    { name: 'drafted_notes', class:'wrap-cell', title: 'Drafted Notes' },
                                    { name: 'reviewed_notes', class:'wrap-cell', title: 'Reviewed Notes' },
                                    { name: 'state_of_property', class:'wrap-cell', title: 'State/County/Street', component: TableCellPropertyDetails },
                                    { name: 'deed_notes', class:'wrap-cell', title: 'Deed Notes' },
                                    { name: 'grantee', class:'wrap-cell', title: 'Grantee' },
                                    { name: 'researching_by', title: 'Researching By/Researching Date', component: TableCellResearchingDetails },
                                    { name: 'research_completed_by', title: 'Research Completed By/Research Completed Date', component: TableCellResearchCompletedDetails },
                                    { name: 'working_by', title: 'Working By/Working Date', component: TableCellWorkingDetails },
                                    { name: 'pending_by', title: 'Pending By/Pending Date', component: TableCellPendingDetails },
                                    { name: 'drafted_by', title: 'Drafted By/Drafted Date', component: TableCellDraftedDetails },
                                    { name: 'reviewed_by', title: 'Reviewed By/Reviewed Date', component: TableCellReviewedDetails },
                                    { name: 'completed_by', title: 'Completed Details', component: TableCellCompletedDetails },
                                ]}
                                data={tableRows.map((item) => {
                                    return { ...item, deedResearchStore, generateNewPDF }
                                })}
                            ></TableComp>
                        </div> :
                            <Card 
                                columnAction={[
                                    { name: 'is_researching', title: 'Researching:', component: TableCellCheckboxResearching },
                                    { name: 'is_research_completed', title: 'Research Completed:', component: TableCellCheckboxResearchCompleted },
                                    { name: 'is_working', title: 'Working:', component: TableCellCheckboxWorking },
                                    { name: 'is_pending', title: 'Pending:', component: TableCellCheckboxPending },
                                    { name: 'is_drafted', title: 'Drafted:', component: TableCellCheckboxDrafted },
                                    { name: 'is_reviewed', title: 'Review:', component: TableCellCheckboxReviewed },
                                    { name: '_id', title: 'Completed:', component: TableCellCheckboxCompleted }
                                ]}
                                actionPerformedBy={[
                                    { name: 'researching_by', title: 'By:' },
                                    { name: 'research_completed_by', title: 'By:' },
                                    { name: 'working_by', title: 'By:' },
                                    { name: 'pending_by', title: 'By:' },
                                    { name: 'drafted_by', title: 'By:' },
                                    { name: 'reviewed_by', title: 'By:' },
                                    { name: 'completed_by', title: 'By:' },
                                           
                                ]}
                                actionPerformedDate={[
                                    { name: 'researching_date', title: 'Date:', component: FormateDateWithTime },
                                    { name: 'research_completed_date', title: 'Date:' },
                                    { name: 'working_date', title: 'Date:', component: FormateDateWithTime },
                                    { name: 'pending_date', title: 'Date:', component: FormateDateWithTime },
                                    { name: 'drafted_date', title: 'Date:', component: FormateDateWithTime },
                                    { name: 'reviewed_date', title: 'Date:', component: FormateDateWithTime },
                                    { name: 'completed_date', title: 'Date:',  component: FormateDateWithTime },
                                ]}

                                actionPerformedNote={[
                                    { name: 'researching_notes', title: 'Note:' },
                                    { name: 'research_completed_notes', title: 'Note:' },
                                    { name: 'pending_notes', title: 'Note:' },
                                    { name: 'drafted_notes', title: 'Note:' },
                                    { name: 'reviewed_notes', title: 'Note:' },
                                ]}
                                isLoading={deedResearchStore.isLoading || clientStore.isLoading}
                                unique_key='_id'
                                data={tableRows.map((item) => {
                                    return { ...item, deedResearchStore, generateNewPDF }
                                })}
                            ></Card>
                        }

                        <Pagination
                            currentPage={deedsData.currentPage}
                            totalPages={deedsData.totalPage}
                            totalItems={deedsData.totalItem}
                            itemsPerPage={deedsData.itemPerPage}
                            isFiltered={deedsData.isFiltered}
                            totalAllItems={deedsData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(DeedResearchPage)
