import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { FormateDate } from '../commonComponent/commonFuction'
import TableCellYesNo from './TableCellYesNo'
import TableCellTo from './TableCellTo'
import TableCellType from './TableCellType'
import { debounceSearch } from '../../utils/debounce'
import { TableCellEmailDetails } from './TableCellEmailDetail'
import { TableCellErrorDetails } from './TableCellErrorData'
interface Props {
    rootStore: RootStore,
}

const EmailLogsPage: React.FC<Props> = ({ rootStore }) => {
    const { emailLogStore, authStore, setTitle } = rootStore
    const { isAdmin } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, emailLog, fetchEmailLogCronData, isPageOpened } = emailLogStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (page: number) => {
        fetchEmailLogCronData(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchEmailLogCronData(1, e.target.value.trim()))
    }

    useEffect(() => {
        if(isAdmin && !isPageOpened){
            fetchEmailLogCronData(1, '')
        }
    }, [])

    useEffect(() => {
        setTitle('Email Logs | OathZephyr')
    }, [setTitle])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Email Logs
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'user_id[0].name', title: 'Sent By' },
                                    { name: 'type', title: 'Type', component: TableCellType },
                                    { name: 'to', title: 'To', component: TableCellTo },
                                    { name: 'sent_status', title: 'Is Success', component: TableCellYesNo },
                                    { name: 'createdAt', title: 'Created At', component: FormateDate },
                                    { name: '_id', title: 'Email Details', component: TableCellEmailDetails },
                                    { name: 'error', title: 'Request Data', component: TableCellErrorDetails },
                                ]}
                                data={emailLog.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={emailLog.currentPage}
                            totalPages={emailLog.totalPage}
                            totalItems={emailLog.totalItem}
                            itemsPerPage={emailLog.itemPerPage}
                            // isFiltered={emailLog.isFiltered}
                            totalAllItems={emailLog.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(EmailLogsPage)
