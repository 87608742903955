import React, { useEffect, useState } from 'react'
import RootStore from '../store/Root'
import DefaultLayout from './layout/DefaultLayout'
import { useForm } from 'react-hook-form'
import { login } from '../api/SuperAdminActions'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { onChangeEmail } from './commonComponent/WithoutAcceptSpace'

interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  [Key: string]: T;
}

const LoginPage: React.FC<Props> = ({ rootStore }) => {
    const { setTitle } = rootStore
    const [apiErrorMessage, setApiErrorMessage] = useState('')
    const [isApiError, setIsApiError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { register, handleSubmit, errors } = useForm()
    const history = useHistory()

    const onSubmit = async (data: Dictionary<string>) => {
        setIsLoading(true)
        setIsApiError(false)
        setApiErrorMessage('')

        try {
            const response = await login(data.email, data.password)
            setIsLoading(false)

            if (response.data.success === 0) {
                setIsApiError(true)
                setApiErrorMessage(response.data.message)
                return
            }

            const token = response.data.data.token
            rootStore.authStore.setSuperAdmin(data.email)
            rootStore.authStore.setUserRole(response.data.data.role)
            rootStore.authStore.setAuthToken(token)
            history.push('/dashboard')
            window.location.reload()
        } catch (e) {
            setIsLoading(false)
            setIsApiError(true)
            setApiErrorMessage(e.message)
        }
    }

    useEffect(() => {
        setTitle('Login | OathZephyr')
    }, [])

    return <DefaultLayout rootStore={rootStore}>
        <div className='row justify-content-md-center pt-5'>
            <div className='col col col-md-4'>
                <h3 className="panel-title">Please Sign In</h3>
                {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                    {apiErrorMessage}
                </div>}

                <form onSubmit={handleSubmit(onSubmit)} >
                    <fieldset>
                        <div className="form-group">
                            <label>Email address</label>
                            <input
                                ref={register({
                                    required: 'This field is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address'
                                    }
                                })}
                                name='email'
                                onChange={onChangeEmail}
                                type='text'
                                className={classNames('form-control', { 'is-invalid': errors.email })}
                                placeholder='Enter email'
                            />
                            {errors.email && (<div className='invalid-feedback'>{errors.email.message}</div>)}
                        </div>

                        <div className='form-group'>
                            <label>Password</label>
                            <input
                                name='password'
                                ref={register({
                                    required: 'This field is required'
                                })}
                                type='password'
                                className={classNames('form-control', { 'is-invalid': errors.password })}
                                placeholder='Enter password'
                            />
                            {errors.password && (<div className='invalid-feedback'>{errors.password.message}</div>)}
                        </div>

                        <button type='submit' className="btn btn-primary btn-block" disabled={isLoading}>
                            {isLoading ? 'Loading...' : 'Login'}
                        </button>
                    </fieldset>
                </form>
            </div>
        </div>
    </DefaultLayout>
}

export default observer(LoginPage)